import axios from 'axios';
import React, { useState } from 'react';
import baseURL from '../components/config';

const Contact = () => {
    const [formData, setFormData] = useState({
        fullName: '',
        Email: '',
        message: '',
        tel: '',
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    
    const handleSubmit = async () => {
        try {
            const response = await axios.post(`${baseURL}/api/contact_us`, formData);
            // console.log(response.data)

            alert("Your enquiry has been sent!");
            window.location.reload()
        } catch (error) {
            console.error('Error in sending enquiry:', error);
        }
    };

    return (
        <div>
            <main className="pc-main">
                <h1 className="contact-title">Contact Us</h1>
                <section className="contact-section">
                    <div className="contact-form">
                        <h2>Get in Touch</h2>
                        <input type="text" id="name" placeholder="Your Name" required name="fullName" onChange={handleChange}/><br/><br/>
                        <input type="email" id="email" placeholder="Your Email" required name="Email" onChange={handleChange}/><br/><br/>
                        <input type="tel" id="phone" placeholder="Your Phone Number" name="tel" onChange={handleChange}/><br/><br/>
                        <textarea id="message" placeholder="Your Message" rows="6" required name="message" onChange={handleChange}></textarea><br/><br/>
                        <input type="submit" value="Send Message" onClick={handleSubmit}/>
                    </div>

                    <div className="contact-info">
                        <div>
                            <h2>Address</h2>
                            <p>Sydney -- U2203, 233 Castlereagh st, Sydney, 2000</p>
                        </div>
                        <div>
                            <h2>Email</h2>
                            <p>info@aacsi.org.au</p>
                        </div>
                        <div>
                            <h2>Phone</h2>
                            <p>0425 600 922 -- Chun Wu</p>
                        </div>
                    </div>
                </section>
            </main>

            <main className="mobile-main">
                <h1 className="contact-title">Contact Us</h1>
                <section className="contact-section">
                    <div className="contact-form">
                        <h2>Get in Touch</h2>
                        <form onSubmit={handleSubmit}>
                            <input type="text" id="name" placeholder="Your Name" required/><br/><br/>
                            <input type="email" id="email" placeholder="Your Email" required/><br/><br/>
                            <input type="tel" id="phone" placeholder="Your Phone Number"/><br/><br/>
                            <textarea id="message" placeholder="Your Message" rows="6" required></textarea><br/><br/>
                            <input type="submit" value="Send Message"/>
                        </form>
                    </div>

                    <div className="contact-info">
                        <div>
                            <h2>Address</h2>
                            <p>Sydney -- U2203, 233 Castlereagh st, Sydney, 2000</p>
                        </div>
                        <div>
                            <h2>Email</h2>
                            <p>info@aacsi.org.au</p>
                        </div>
                        <div>
                            <h2>Phone</h2>
                            <p>0425 600 922 -- Chun Wu</p>
                        </div>
                    </div>
                </section>
            </main>
        </div>
    );
  }
  
  export default Contact;