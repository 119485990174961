import React from 'react';
import { Link, useLocation } from 'react-router-dom';

function RefreshableLink({ to, children }) {
  const location = useLocation();

  const handleClick = () => {
    if (location.pathname === to) {
      window.location.reload();
    }
  };

  return (
    <Link to={to} onClick={handleClick}>
      {children}
    </Link>
  );
}

export default RefreshableLink;