import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import baseURL from './config';

const Event = ({ eventID, eventName, ownerID, description, deadline, approved, eventIsAdmin, isJoined, fetchEventsAndJoinStatus, topEventID }) => {
    const [jwtToken, setJwtToken] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);
    const [isOwner, setIsOwner] = useState(false);
    const navigate = useNavigate();

    const [copySuccess, setCopySuccess] = useState(false);
    const [deadlineDateString, setDeadlineDateString] = useState(null);

    const copyToClipboard = () => {
        const eventURL = window.location.href; // Get the current URL
        navigator.clipboard.writeText(`${eventURL}/`); // Copy URL to clipboard
        setCopySuccess(true); // Set copy success to true
        setTimeout(() => setCopySuccess(false), 2000); // Reset copy success after 2 seconds
    };

    useEffect(() => {
        const token = localStorage.getItem('jwtToken');
        setJwtToken(token);

        const localIsAdmin = localStorage.getItem('isAdmin');
        localIsAdmin === null || localIsAdmin === 'false' ? setIsAdmin(false) : setIsAdmin(true);

        // checking if current user is owner of this event
        const userID = localStorage.getItem('userID');

        if (ownerID && userID === ownerID.toString()) {
            setIsOwner(true);
            // console.log(isOwner)
        }

        // console.log(isJoined);

        // Convert deadline format to dd/mm/yyyy
        const deadlineDate = new Date(deadline);
        const day = String(deadlineDate.getDate()).padStart(2, '0');
        const month = String(deadlineDate.getMonth() + 1).padStart(2, '0');
        const year = deadlineDate.getFullYear();
        setDeadlineDateString(`${day}/${month}/${year}`);
    }, []);

    const checkTokenExipry = () => {
        if (!jwtToken) {
            alert('Please log in first')
            navigate('/login');
            return false;
        } else {
            const decodedToken = jwtDecode(jwtToken);
            const currentTime = Date.now() / 1000; // Convert to seconds
            // check if user's jwtToken expired
            if (decodedToken.exp < currentTime) {
                alert('Your session has expired, Please log in again');
                localStorage.clear()
                navigate('/login');
                return false;
            }
        }
        return true;
    }

    const handleJoin = async () => {
        if (checkTokenExipry()) {
            // Check if user has filled all required information in profile
            try {
                // Get JWT token from local storage
                const jwtToken = localStorage.getItem('jwtToken');
                // console.log(jwtToken);

                const response = await fetch(`${baseURL}/api/personal_detail/get`, {
                    method: 'POST',
                    headers: {
                        'Content-type': 'application/json',
                        Authorization: `Bearer ${jwtToken}`
                    }
                });

                const data = await response.json();
                // console.log(data);

                // Check if any required information is missing
                if (!data[0].Email ||
                    !data[0].userName ||
                    !data[0].fullName ||
                    !data[0].phoneNumber ||
                    !data[0].nationality ||
                    !data[0].gender ||
                    !data[0].DOB ||
                    !data[0].WeChatID ||
                    !data[0].graduateSchool ||
                    !data[0].degreeInfo ||
                    !data[0].courseMajor ||
                    !data[0].phdGraduateSchool ||
                    !data[0].advisor ||
                    !data[0].graduateTime ||
                    !data[0].researchAchievement ||
                    !data[0].expectSalary ||
                    !data[0].chosenCities ||
                    !data[0].chosenEmployers
                ) {
                    // If any required information is missing, show alert and navigate to profile page
                    alert("Please fill profile before joining an event");
                    localStorage.setItem('isEditing', true); // make isEditing true for profile page
                    navigate('/profile'); // Navigate to profile page
                    return; // Stop execution
                }
            } catch (error) {
                console.error('Error fetching user details:', error);
            }


            // Join Event
            try {
                const response = await axios.post(`${baseURL}/api/events/join`, { 'userID': localStorage.getItem('userID'), 'eventID': eventID }, {
                    headers: {
                        Authorization: `Bearer ${jwtToken}`
                    }
                });
                if (response.status === 200) {
                    alert(`You have successfully joined ${eventName}!`);
                    // Update local state or trigger a refetch of events data
                    fetchEventsAndJoinStatus();
                } else {
                    alert('Failed to join event');
                }
            } catch (error) {
                console.error('Failed to join event:', error);
            }
        }
    };

    const handleExit = async () => {
        const isConfirmed = window.confirm("Are you sure you want to exit the event?");
        if (checkTokenExipry()) {
            if (isConfirmed) {
                try {
                    const response = await axios.post(`${baseURL}/api/events/exit`, { 'userID': localStorage.getItem('userID'), 'eventID': eventID }, {
                        headers: {
                            Authorization: `Bearer ${jwtToken}`
                        }
                    });
                    if (response.status === 200) {
                        alert(`You have successfully exit ${eventName}!`);
                        // Update local state or trigger a refetch of events data
                        fetchEventsAndJoinStatus();
                    }
                } catch (error) {
                    console.error('Failed to exit event:', error);
                }
            }
        }
    };

    const handleDelete = async () => {
        const isConfirmed = window.confirm("Are you sure you want to delete the event?");
        if (checkTokenExipry()) {
            if (isConfirmed) {
                try {
                    const response = await axios.delete(`${baseURL}/api/events/delete`, {
                        headers: {
                            Authorization: `Bearer ${jwtToken}`,
                            'Content-Type': 'application/json'
                        },
                        data: { 'eventID': eventID }
                    });
                    window.location.reload();
                    // console.log(response.data)
                    if (response.status === 200) {
                        // Update local state or trigger a refetch of events data
                        fetchEventsAndJoinStatus();
                    }
                } catch (error) {
                    console.error('Failed to delete event:', error);
                }
            }
        }
    };

    const handleApprove = async () => {
        if (checkTokenExipry()) {
            try {
                const response = await axios.put(`${baseURL}/api/events/approve`, { 'eventID': eventID }, {
                    headers: {
                        Authorization: `Bearer ${jwtToken}`
                    }
                });
                // console.log(response.data)
                if (response.status === 200) {
                    //  navigate to events page and reload the page
                    navigate('/events');
                    window.location.reload();
                }
            } catch (error) {
                console.error('Failed to approve event:', error);
            }
        }
    };

    const handleDisapprove = async () => {
        if (checkTokenExipry()) {
            try {
                const response = await axios.put(`${baseURL}/api/events/disapprove`, { 'eventID': eventID }, {
                    headers: {
                        Authorization: `Bearer ${jwtToken}`
                    }
                });
                // console.log(response.data)
                if (response.status === 200) {
                    //  navigate to events page and reload the page
                    navigate('/events');
                    window.location.reload();
                }
            } catch (error) {
                console.error('Failed to disapprove event:', error);
            }
        }
    };

    const handleEditEvent = () => {
        navigate(`/events/edit/${eventID}`);
    };

    const handleExport = async () => {
        if (checkTokenExipry()) {
            try {
                const response = await axios.post(`${baseURL}//api/event_users_export`, { 'eventID': eventID, 'eventName': eventName }, {
                    headers: {
                        Authorization: `Bearer ${jwtToken}`
                    },
                    responseType: 'blob' // Set response type to blob to handle binary data
                });
                // Create a URL for the Excel file data
                const url = window.URL.createObjectURL(new Blob([response.data]));

                // Create a link element to trigger the download
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `users_from_${eventName}_${eventID}.xlsx`); // Set the filename for the downloaded file
                document.body.appendChild(link);

                // Click the link to trigger the download
                link.click();

                // Remove the link from the document
                document.body.removeChild(link);
            } catch (error) {
                console.error('Failed to export users from event:', error);
            }
        }
    }

    const renderAdminButtons = () => {
        if (isAdmin) {
            return (
                <div className="admin-buttons">
                    {/* <img className='admin-images' src="images/edit.png" alt="Edit" onClick={handleEditEvent} />
                    <img className='admin-images' src="images/delete.png" alt="Delete" onClick={handleDelete} />
                    <img className='admin-images' src="images/export_icon.png" alt="Export All Users" onClick={handleExport} /> */}
                    {
                        eventIsAdmin ?
                            <div></div>
                            :
                            <div>
                                {approved ? (
                                    <span className="event-icon-info">
                                        <img
                                            className="admin-images"
                                            src="images/disapprove.png"
                                            alt="disapprove"
                                            onClick={handleDisapprove}
                                        />
                                        <span className="tooltip">Disapprove</span>
                                    </span>
                                ) : (
                                    <span className="event-icon-info">
                                        <img
                                            className="admin-images"
                                            src="images/approve.png"
                                            alt="approve"
                                            onClick={handleApprove}
                                        />
                                        <span className="tooltip">Approve</span>
                                    </span>
                                )}
                            </div>
                    }
                </div>
            );
        }
    };

    return (
        <div className='event-card'>
            <div className='title-container'>
                <h2 className='event-name' style={{ maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                    {eventName}
                </h2>
                {
                    eventID === topEventID ?
                        <div className="top-event-label">
                            <span className="badge">TOP EVENT!</span>
                        </div>
                        :
                        null
                }
            </div>
            {renderAdminButtons()}
            {
                eventIsAdmin ?
                <div className="admin-created">admin created</div>
                :
                null
            }
            {approved ? null : <p className='admin-tag' id='approve'>待审核</p>}
            <div className='event-deadline'>Deadline: {deadlineDateString}</div>
            <p className='event-description' style={{ maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{description}</p>
            <div className='eventButton'>
                <button className='cta-button' onClick={() => navigate(`/events/${eventID}`)}>View</button>
            </div>
        </div>
    );

};

Event.propTypes = {
    eventID: PropTypes.number.isRequired,
    eventName: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
};

export default Event;
