import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Filter from '../components/Filter';
import WithAuthCheck from '../components/withAuthCheck';
import baseURL from '../components/config';

const Users = () => {
    const [users, setUsers] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [filteredOptions, setFilteredOptions] = useState({});
    const [selectedFile, setSelectedFile] = useState('');
    
    
    useEffect(() => {
        fetchAllUsersData();
    }, []);

    async function fetchAllUsersData() {
        try {
            const response = await axios.get(`${baseURL}/api/all_users`);
            setUsers(response.data.users);
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    }

    const handleSearchChange = (e) => {
        setSearchValue(e.target.value);
    };

    const handleFilterSelect = (selectedOptions) => {
        setFilteredOptions(selectedOptions);
    };     

    const handleImport = async () => {
        if (selectedFile === null || selectedFile === undefined) {
            alert("Error: Can not import empty file!")
            return;
        }

        const isConfirmed = window.confirm("Are you sure you want to import the file?");
        const formData = new FormData();
        formData.append('file', selectedFile);
        if (isConfirmed) {
            try {
                const response = await axios.post(`${baseURL}/api/import-users-from-excel`, formData, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
                        'Content-Type': 'multipart/form-data'
                    }
                });
                alert(response.data.message)
                // console.log(response.data);
            } catch (error) {
                console.error('Error importing user details:', error);
            }
        }
    };

    const handleSelectFile = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
        // console.log(file)
    }

    const filteredUsers = users.filter(user => {
        let isUserIncluded = true;
    
        if (searchValue) {
            isUserIncluded = (
                user.fullName.toLowerCase().includes(searchValue.toLowerCase()) ||
                user.userName.toLowerCase().includes(searchValue.toLowerCase()) ||
                user.Email.toLowerCase().includes(searchValue.toLowerCase())
            );
        }

        if (Object.keys(filteredOptions).length > 0) {
            Object.keys(filteredOptions).forEach(option => {
                if (filteredOptions[option].length > 0) {
                    switch (option) {
                        case 'Nationality':
                            isUserIncluded = false;
                            if (filteredOptions[option].includes(user.nationality)) {
                                isUserIncluded = true;
                            } else if (filteredOptions[option].includes("Other")) {
                                if (user.nationality !== "Chinese" && user.nationality !== "Australian" && user.nationality !== "") {
                                    isUserIncluded = true;
                                }
                            }
                            break;
                        case 'Expected Employer':
                            isUserIncluded = false;
                            // console.log(filteredOptions[option])
                            // console.log(user.chosenEmployers)
                            // console.log(!filteredOptions[option].includes(user.chosenEmployers))
                            user.chosenEmployers.map((e, index) => {
                                // console.log(e)
                                // console.log(filteredOptions[option])
                                if (filteredOptions[option].includes(e)) {
                                    isUserIncluded = true;
                                } else if (filteredOptions[option].includes("其他")) {
                                    if (e !== "创业" && e !== "政府机构" && e !== "国企" && e !== "高校" && e !== "无要求") {
                                        isUserIncluded = true;
                                    }
                                }
                            })

                            break;
                        case 'Expected Salary':
                            // console.log(user.expectSalary)
                            if (!filteredOptions[option].includes(user.expectSalary)) {
                                isUserIncluded = false;
                            }
                            break;
                        case 'Need Help':
                            const needHelpValue = user.needHelp ? "True" : "False";
                            if (!filteredOptions[option].includes(needHelpValue)) {
                                isUserIncluded = false;
                            }
                            break;
                        default:
                            break;
                    }
                }
            });
        }
        
        return isUserIncluded;
    });

    return (
        <div className="event-section users-section">
            <div className="event-main-section users-main-section">
                <h1>All Users</h1>
                <div className='user-header'>
                    <div className="search">
                        <input type="text" value={searchValue} onChange={handleSearchChange} placeholder="Search by name or email" />
                    </div>
                    <div className='filter-section'>
                        <Filter
                            options={[
                                { name: 'Nationality', children: ['Chinese', 'Australian', 'Other'] },
                                { name: 'Expected Employer', children: ['无要求', '高校', '国企', '政府机构', '创业', '其他'] },
                                { name: 'Expected Salary', children: ['Less $30,000', '$30,000-$60,000', '$60,000-$90,000', '$90,000-$120,000', '$120000+'] },
                                { name: 'Need Help', children: ['True', 'False'] },
                            ]}
                            onSelect={handleFilterSelect}
                        />
                    </div>
                    {/* <div>
                        <div className="print">
                            <button htmlFor="import" className="cta-button" onClick={handleImport}>Import</button>
                            <input type="file" id="import" className="import-input" onChange={handleSelectFile} />
                        </div>
                    </div> */}
                </div>
                <div className="user-cards">
                    {filteredUsers.map(user => (
                        <div className="event-card user-card" key={user.userID}>
                            <h2>
                                {user.fullName}
                                {user.isAdmin && <span className="admin-tag">Admin</span>}
                            </h2>
                            <p>Username: {user.userName}</p>
                            <p>Email: {user.Email}</p>
                            <p>Nationality: {user.nationality}</p>
                            <p>Expected Employer: {user.chosenEmployers.join(', ')}</p>
                            <p>Expected Salary: {user.expectSalary}</p>
                            <p>Need Help: {user.needHelp ? "True" : "False"}</p>
                            <div className='detail'><Link className="cta-button" to={`/profile/${user.userID}`}>Detail</Link></div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default WithAuthCheck(Users);
