import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import WithAuthCheck from '../components/withAuthCheck';
import baseURL from '../components/config';

const EventEdit = () => {
    const { eventID } = useParams();
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        eventName: '',
        description: '',
        deadline: '',
        eventID: ''
    });

    const maxLengths = {
        eventName: 60,
        description: 5000
    }
    
    useEffect(() => {
        // Fetch event details based on eventID
        axios.get(`${baseURL}/api/events/get?eventID=${eventID}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
                'Content-Type': 'application/json'
            }
        }).then(response => {
            const deadlineDate = new Date(response.data.event[7]);
            const day = String(deadlineDate.getDate()).padStart(2, '0');
            const month = String(deadlineDate.getMonth() + 1).padStart(2, '0');
            const year = deadlineDate.getFullYear();
            const deadlineDateString = `${year}-${month}-${day}`;
            setFormData({
                eventName: response.data.event[1],
                description: response.data.event[2],
                deadline: deadlineDateString,
                eventID: eventID
            });
            // console.log(response.data.event)
        })
        .catch(error => {
            console.error('Error fetching event details:', error);
        });
    }, [eventID]);
    

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async () => {
        const isConfirmed = window.confirm("Are you sure you want to save the changes?");
        if (isConfirmed) {
            try {
                const response = await axios.put(`${baseURL}/api/events/edit`, formData, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
                        'Content-Type': 'application/json'
                    }
                });
                // console.log(response.data);
                navigate('/events');
            } catch (error) {
                console.error('Error editing events:', error);
            }
        }
    };

    const handleCancel = () => {
        navigate(-1);
    };

    return (
        <div className="event-main">
            <div className='back-button' onClick={handleCancel}>
                <img id='back-icon' src='/images/back.png' alt="Back" />
            </div>
            <h1 className='profile-title'>Edit Event</h1>
            <div className="userInfo-container">
                <label htmlFor="title">Title</label>
                <input className='userAnswer' maxLength={maxLengths.eventName} type="text" name="eventName" value={formData.eventName} onChange={handleChange} />
            </div>
            <div className="userInfo-container">
                <label htmlFor="description">Description</label>
                <textarea className='userAnswer'maxLength={maxLengths.description} id='description' name="description" value={formData.description} onChange={handleChange} />
            </div>
            <div className="userInfo-container">
                <label htmlFor="deadline">Deadline</label>
                <input className='userAnswer' type="date" name="deadline" value={formData.deadline} onChange={handleChange} min={new Date().toISOString().split('T')[0]} />
            </div>
            <div className='profile-edit-div'>
                <div className="buttonFlex">
                    <button className='cta-button' onClick={handleSubmit}>Save</button>
                </div>
            </div>
        </div>
    );
};

export default WithAuthCheck(EventEdit);
