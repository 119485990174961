import React from 'react';
import { Link  } from 'react-router-dom';

const Mission = () => {
    return ( 
        <div>
            <main className="pc-main">
                <section className="about-section">
                    <div className="about-description-section">
                        <h1>Our Mission</h1>
                        <p>Our goal is to promote communication,exchange, and mutual assistance among the Chinese scholar community in Australia and to provide comprehensive support and services to our members.</p>
                        <p>1. Foster communication and mutual support among the community of Chinese scholars in Australia.</p>
                        <p>2. Enhance the exchange and communication between Chinese scholars in Australia and their counterparts in China.</p> 
                        <p>3. Promote and assist collaboration between Chinese research institutions, global enterprises, and Chinese scholars in Australia.</p>
                        <p>4. Support the implementation of Chinese scholars' research outcomes in businesses, or encourage and help them to turn their research into business ventures.</p>
                        <p>5. Give a voice to Chinese scholars in Australia and elevate their status in Australian society</p>
                        <Link to="/onshore_support" className="cta-button">Next</Link>
                    </div>
                    <div className="about-image-section">
                        <img src="images/Mission2.jpg" alt="about"/>
                    </div>
                </section>
            </main>

            <main className="mobile-main">
                <section className="about-section">
                    <div className="about-description-section">
                        <h1>Our Mission</h1>
                        <p>Our goal is to promote communication,exchange, and mutual assistance among the Chinese scholar community in Australia and to provide comprehensive support and services to our members.</p>
                        <div className="about-image-section">
                            <img src="images/Mission2.jpg" alt="about"/>
                        </div>
                        <ul>
                            <li>Foster communication and mutual support among the community of Chinese scholars in Australia.</li>
                            <li>Enhance the exchange and communication between Chinese scholars in Australia and their counterparts in China.</li> 
                            <li>Promote and assist collaboration between Chinese research institutions, global enterprises, and Chinese scholars in Australia.</li>
                            <li>Support the implementation of Chinese scholars' research outcomes in businesses, or encourage and help them to turn their research into business ventures.</li>
                            <li>Give a voice to Chinese scholars in Australia and elevate their status in Australian society</li>
                        </ul>
                        <Link to="/onshore_support" className="cta-button">Next</Link>
                    </div>
                    
                </section>
            </main>
        </div>
    );
}
  
  export default Mission;