import React from 'react';
import { Link  } from 'react-router-dom';

const Program = () => {
    return ( 
        <div>
            <main className="pc-main">
                <section className="about-section">
                    <div className="about-description-section">
                        <h1>Sino-Australian Torch Program</h1>
                        <h3>Active Collaboration with the Sino-Australian Torch Program</h3>
                        <p>In 2016, Chinese Prime Minister Li Keqiang visited Sydney and signed a Memorandum of Understanding with the University of New South Wales to jointly build a Torch Innovation Park. Key members of our association, as representatives of high-level talents, welcomed Prime Minister Li Keqiang.                    Members of our association have successfully completed projects under the Torch Program.</p>
                        <Link to="/international_events" className="cta-button">Next</Link>
                    </div>
                    <div className="about-image-section">
                        <img src="images/torch-program.jpg" alt="Sino-Australian Torch Program"/>
                    </div>
                </section>
            </main>

            <main className="mobile-main">
                <section className="about-section">
                    <div className="about-description-section">
                        <h1>Sino-Australian Torch Program</h1>
                        <h3>Active Collaboration with the Sino-Australian Torch Program</h3>
                        <div className="about-image-section">
                            <img src="images/torch-program.jpg" alt="Sino-Australian Torch Program"/>
                        </div>
                        <p>In 2016, Chinese Prime Minister Li Keqiang visited Sydney and signed a Memorandum of Understanding with the University of New South Wales to jointly build a Torch Innovation Park. Key members of our association, as representatives of high-level talents, welcomed Prime Minister Li Keqiang.                    Members of our association have successfully completed projects under the Torch Program.</p>
                        <Link to="/international_events" className="cta-button">Next</Link>
                    </div>
                </section>
            </main>
        </div>
    );
}
  
  export default Program;