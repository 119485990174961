import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import baseURL from '../components/config';

const EventPage = () => {
    const { eventID } = useParams();
    const navigate = useNavigate();
    const [isAdmin, setIsAdmin] = useState(false);
    const [isAgent, setIsAgent] = useState(false);
    const [isTop, setIsTop] = useState(false);
    const [eventMembers, setEventMembers] = useState([]);
    const [isOwner, setIsOwner] = useState(false);
    const [isJoined, setIsJoined] = useState(false);
    const [jwtToken, setJwtToken] = useState(null);
    const [notExist, setNotExist] = useState(true);
    const textareaRef = useRef(null);
    const [formData, setFormData] = useState({
        eventID: '',
        eventName: '',
        description: '',
        deadline: '',
        ownerName: '',
        ownerID: '',
        isAdmin: '',
        status: ''
    });

    const maxLengths = {
        eventName: 60,
        description: 5000
    }

    const [copySuccess, setCopySuccess] = useState(false);

    // const copyToClipboard = () => {
    //     const eventURL = window.location.href; // Get the current URL
    //     navigator.clipboard.writeText(`${eventURL}/`); // Copy URL to clipboard
    //     setCopySuccess(true); // Set copy success to true
    //     setTimeout(() => setCopySuccess(false), 2000); // Reset copy success after 2 seconds
    // };

    const copyToClipboard = () => {
        const eventURL = window.location.href; // Get the current URL
    
        // Create a temporary input element
        const tempInput = document.createElement('input');
        tempInput.value = `${eventURL}/`;
        document.body.appendChild(tempInput);
    
        // Select the text inside the input element
        tempInput.select();
    
        try {
            // Attempt to copy the text to the clipboard
            const success = document.execCommand('copy');
            if (success) {
                // Set copy success to true if successful
                setCopySuccess(true);
                setTimeout(() => setCopySuccess(false), 2000); // Reset copy success after 2 seconds
            } else {
                console.error('Failed to copy to clipboard');
            }
        } catch (error) {
            console.error('Failed to copy to clipboard:', error);
        }
    
        // Remove the temporary input element
        document.body.removeChild(tempInput);
    }
    

    useEffect(() => {
        const token = localStorage.getItem('jwtToken');
        setJwtToken(token);

        const localIsAdmin = localStorage.getItem('isAdmin');
        localIsAdmin === null || localIsAdmin === 'false' ? setIsAdmin(false) : setIsAdmin(true);

        const localIsAgent = localStorage.getItem('isAgent');
        // console.log(localStorage.getItem('isAgent'))
        localIsAgent === null || localIsAgent === 'false' ? setIsAgent(false) : setIsAgent(true);
        // console.log(localIsAgent);
    }, []);


    useEffect(() => {
        const fetchEventMembers = async () => {
            try {
                const response = await axios.get(`${baseURL}/api/eventmembers/get?eventID=${eventID}&userID=${localStorage.getItem('userID')}`);
                // console.log(response.data.members);
                if (response.status === 200) {
                    setEventMembers(response.data.members);
                }
            } catch (error) {
                console.error('Error fetching event members:', error);
            }
        };

        fetchEventMembers();
    }, [eventID]);

    useEffect(() => {
        // Fetch event details based on eventID
        axios.get(`${baseURL}/api/events/get?eventID=${eventID}&userID=${localStorage.getItem('userID')}`).then(response => {
            const deadlineDate = new Date(response.data.event[7]);
            const day = String(deadlineDate.getDate()).padStart(2, '0');
            const month = String(deadlineDate.getMonth() + 1).padStart(2, '0');
            const year = deadlineDate.getFullYear();
            const deadlineDateString = `${day}/${month}/${year}`;
            setFormData({
                eventID: eventID,
                eventName: response.data.event[1],
                description: response.data.event[2],
                ownerName: response.data.event[3],
                ownerID: response.data.event[4],
                isAdmin: response.data.event[5],
                status: response.data.event[6],
                deadline: deadlineDateString,
            });
            response.data.isJoined ? setIsJoined(true) : setIsJoined(false);
            response.data.isTop ? setIsTop(true) : setIsTop(false);
            // console.log(response.data.event)
            // console.log(response.data.isJoined)
            // console.log(response.data.isTop)
            // checking if current user is owner of this event
            const userID = localStorage.getItem('userID');
            const ownerID = response.data.event[4];
            if (userID && parseInt(userID) === ownerID) {
                setIsOwner(true);
                // console.log(isOwner)
            }
            // console.log(response.data.event)
            setNotExist(false);
        }).catch(error => {
            console.error('Error fetching event details:', error);
        });
    }, [eventID]);

    const checkTokenExipry = () => {
        if (!jwtToken) {
            alert('Please log in first')
            navigate('/login');
            return false;
        } else {
            const decodedToken = jwtDecode(jwtToken);
            const currentTime = Date.now() / 1000; // Convert to seconds
            // check if user's jwtToken expired
            if (decodedToken.exp < currentTime) {
                alert('Your session has expired, Please log in again');
                localStorage.clear()
                navigate('/login');
                return false;
            }
        }
        return true;
    }

    const handleEdit = () => {
        navigate(`/events/edit/${eventID}`);
    }

    const handleJoin = async () => {
        if (checkTokenExipry()) {
            // Check if user has filled all required information in profile
            try {
                // Get JWT token from local storage
                const jwtToken = localStorage.getItem('jwtToken');
                // console.log(jwtToken);

                const response = await fetch(`${baseURL}/api/personal_detail/get`, {
                    method: 'POST',
                    headers: {
                        'Content-type': 'application/json',
                        Authorization: `Bearer ${jwtToken}`
                    }
                });

                const data = await response.json();
                // console.log(data);

                // Check if any required information is missing
                if (!data[0].Email ||
                    !data[0].userName ||
                    !data[0].fullName ||
                    !data[0].phoneNumber ||
                    !data[0].nationality ||
                    !data[0].gender ||
                    !data[0].DOB ||
                    !data[0].WeChatID ||
                    !data[0].graduateSchool ||
                    !data[0].degreeInfo ||
                    !data[0].courseMajor ||
                    !data[0].phdGraduateSchool ||
                    !data[0].advisor ||
                    !data[0].graduateTime ||
                    !data[0].researchAchievement ||
                    !data[0].expectSalary ||
                    !data[0].chosenCities ||
                    !data[0].chosenEmployers
                ) {
                    // If any required information is missing, show alert and navigate to profile page
                    alert("Please fill profile before joining an event");
                    localStorage.setItem('isEditing', true); // make isEditing true for profile page
                    navigate('/profile'); // Navigate to profile page
                    return; // Stop execution
                }
            } catch (error) {
                console.error('Error fetching user details:', error);
            }


            // Join Event
            try {
                const response = await axios.post(`${baseURL}/api/events/join`, { 'userID': localStorage.getItem('userID'), 'eventID': eventID }, {
                    headers: {
                        Authorization: `Bearer ${jwtToken}`
                    }
                });
                if (response.status === 200) {
                    alert(`You have successfully joined ${formData.eventName}!`);
                    // Update local state or trigger a refetch of events data
                    window.location.reload();
                } else {
                    alert('Failed to join event');
                }
            } catch (error) {
                console.error('Failed to join event:', error);
            }
        }
    };


    const handleExit = async () => {
        const isConfirmed = window.confirm("Are you sure you want to exit the event?");
        if (checkTokenExipry()) {
            if (isConfirmed) {
                try {
                    const response = await axios.post(`${baseURL}/api/events/exit`, { 'userID': localStorage.getItem('userID'), 'eventID': eventID }, {
                        headers: {
                            Authorization: `Bearer ${jwtToken}`
                        }
                    });
                    if (response.status === 200) {
                        alert(`You have successfully exit ${formData.eventName}!`);
                        // Update local state or trigger a refetch of events data
                        window.location.reload();
                    }
                } catch (error) {
                    console.error('Failed to exit event:', error);
                }
            }
        }
    };

    const handleDelete = async () => {
        const isConfirmed = window.confirm("Are you sure you want to delete the event?");
        if (checkTokenExipry()) {
            if (isConfirmed) {
                try {
                    const response = await axios.delete(`${baseURL}/api/events/delete`, {
                        headers: {
                            Authorization: `Bearer ${jwtToken}`,
                            'Content-Type': 'application/json'
                        },
                        data: { 'eventID': eventID }
                    });
                    window.location.reload();
                    // console.log(response.data)
                    if (response.status === 200) {
                        // Update local state or trigger a refetch of events data
                        navigate(-1);
                    }
                } catch (error) {
                    console.error('Failed to delete event:', error);
                }
            }
        }
    };

    const handleExport = async () => {
        if (checkTokenExipry()) {
            try {
                const response = await axios.post(`${baseURL}//api/event_users_export`, { 'eventID': eventID, 'eventName': formData.eventName }, {
                    headers: {
                        Authorization: `Bearer ${jwtToken}`
                    },
                    responseType: 'blob' // Set response type to blob to handle binary data
                });
                // Create a URL for the Excel file data
                const url = window.URL.createObjectURL(new Blob([response.data]));

                // Create a link element to trigger the download
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `users_from_${formData.eventName}_${eventID}.xlsx`); // Set the filename for the downloaded file
                document.body.appendChild(link);

                // Click the link to trigger the download
                link.click();

                // Remove the link from the document
                document.body.removeChild(link);
            } catch (error) {
                console.error('Failed to export users from event:', error);
            }
        }
    }

    const handleApprove = async () => {
        if (checkTokenExipry()) {
            try {
                const response = await axios.put(`${baseURL}/api/events/approve`, { 'eventID': eventID }, {
                    headers: {
                        Authorization: `Bearer ${jwtToken}`
                    }
                });
                // console.log(response.data)
                if (response.status === 200) {
                    window.location.reload();
                }
            } catch (error) {
                console.error('Failed to approve event:', error);
            }
        }
    };

    const handleDisapprove = async () => {
        if (checkTokenExipry()) {
            try {
                const response = await axios.put(`${baseURL}/api/events/disapprove`, { 'eventID': eventID }, {
                    headers: {
                        Authorization: `Bearer ${jwtToken}`
                    }
                });
                // console.log(response.data)
                if (response.status === 200) {
                    window.location.reload();
                }
            } catch (error) {
                console.error('Failed to disapprove event:', error);
            }
        }
    };


    const handleCancel = () => {
        navigate('/events');
    };

    const handleTop = async () => {
        checkTokenExipry();

        try {
            const response = await axios.put(`${baseURL}/api/events/top`, { 'eventID': eventID }, {
                headers: {
                    Authorization: `Bearer ${jwtToken}`
                }
            });

            if (response.status === 200) {
                setIsTop(!isTop);
                alert(response.data.message);
            }
        } catch (error) {
            console.error("Failed to make event top:", error);
        }
    }

    const toUserProfile = (userID) => {
        navigate(`/profile/${userID}`);
    };

    // This block adjusts the textarea's height responsively
    useEffect(() => {
        setNotExist(false);
        if (textareaRef.current) {
            // Reset textarea height to auto to properly calculate its scrollHeight
            textareaRef.current.style.height = 'auto';

            // Set textarea height to its scrollHeight to fit the content
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
        }
    }, [formData.description]);

    return (
        <div className="event-main">
            <div className="buttonTotal">
                <div className='back-button' onClick={handleCancel}>
                    <img id='back-icon' src='/images/back.png' alt="Back" />
                </div>
                {isAdmin === true ? (<>
                    <div className='adminButton'>
                        {
                            isTop ?
                                null
                                :
                                <span className="event-icon-info">
                                    <img
                                        className="admin-images"
                                        src="images/top-icon.png"
                                        alt="Pin"
                                        onClick={handleTop}
                                    />
                                    <span className="tooltip">Pin Event</span>
                                </span>
                        }
                        <span className="event-icon-info">
                            <img
                                className="admin-images"
                                src="images/share-icon.png"
                                alt="Share"
                                onClick={copyToClipboard}
                            />
                            <span className="tooltip">Share</span>
                        </span>
                        <span className="event-icon-info">
                            <img
                                className="admin-images"
                                src="images/edit.png"
                                alt="Edit"
                                onClick={handleEdit}
                            />
                            <span className="tooltip">Edit</span>
                        </span>
                        <span className="event-icon-info">
                            <img
                                className="admin-images"
                                src="images/delete.png"
                                alt="Delete"
                                onClick={handleDelete}
                            />
                            <span className="tooltip">Delete</span>
                        </span>
                        <span className="event-icon-info">
                            <img
                                className="admin-images"
                                src="images/export_icon.png"
                                alt="Export All Users"
                                onClick={handleExport}
                            />
                            <span className="tooltip">Export All Users</span>
                        </span>
                        {
                            formData.isAdmin === 1 ?
                                null
                                :
                                <>
                                    {formData.status === 1 ? (
                                        <span className="event-icon-info">
                                            <img
                                                className="admin-images"
                                                src="images/disapprove.png"
                                                alt="disapprove"
                                                onClick={handleDisapprove}
                                            />
                                            <span className="tooltip">Disapprove</span>
                                        </span>
                                    ) : (
                                        <span className="event-icon-info">
                                            <img
                                                className="admin-images"
                                                src="images/approve.png"
                                                alt="approve"
                                                onClick={handleApprove}
                                            />
                                            <span className="tooltip">Approve</span>
                                        </span>
                                    )}
                                </>
                        }
                        <span className="event-icon-info">
                            <img
                                className="admin-images"
                                src="images/share-icon.png"
                                alt="Share Link"
                                onClick={copyToClipboard}
                            />
                            <span className="tooltip">Share Link</span>
                        </span>
                    </div>
                </>)
                    :
                    <span className="event-icon-info">
                        <img
                            className="admin-images"
                            src="images/share-icon.png"
                            alt="Share Link"
                            onClick={copyToClipboard}
                        />
                        <span className="tooltip">Share Link</span>
                    </span>
                }
            </div>
            {copySuccess && <span className="copy-link-msg">Share link copied to clipboard!</span>}
            {/* <h1 className='profile-title'>Event {eventID}</h1> */}
            <form className="event-form">
                <div className="event-section">
                    <label className='event-title' htmlFor="title">Title</label>
                    {notExist && <div className="error-msg">Event not exist</div>}
                    <div className='event-info' maxLength={maxLengths.eventName} type="text" name="eventName">{formData.eventName}</div>
                </div>
                <div className="event-section">
                    <label className='event-title' htmlFor="description">Description</label>
                    <textarea ref={textareaRef} className='event-info' readOnly maxLength={maxLengths.description} name="description" value={formData.description} />
                </div>
                <div className="event-section">
                    <label className='event-title' htmlFor="deadline">Deadline</label>
                    <div className='event-info' name="deadline">{formData.deadline}</div>
                </div>
                {isAdmin === true || isAgent === true ? (
                    <div className='event-section'>
                        <label className='event-title' htmlFor='member'>Members</label>
                        <ol className="member-list">
                            {eventMembers.map((member, index) => (
                                <li key={member[0]} onClick={() => toUserProfile(member[0])}>
                                    <span className="member-number">{index + 1}.</span>
                                    <span className="member-username">{member[1]}</span>
                                    <button className="profile-button">View Profile</button>
                                </li>
                            ))}
                        </ol>
                    </div>
                ) : null}
            </form>
            <div className='profile-edit-div'>
                <div className="buttonFlex">
                    {
                        isOwner ?
                            <div></div>
                            :
                            <div>
                                {
                                    isJoined ?
                                        <button className='cta-button' onClick={handleExit}>
                                            Exit
                                        </button>
                                        :
                                        <button className='cta-button' onClick={handleJoin}>
                                            Join
                                        </button>
                                }
                            </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default EventPage;
