import React from 'react';
import { Link  } from 'react-router-dom';

const Recent = () => {
    return ( 
        <div>
            <main className="pc-main">
                <section className="about-section">
                    <div className="about-description-section">
                        <h1>Recent Projects 2023</h1>
                        <h3>- West Lake University Information Session</h3>
                        <p>The event aims to foster communication and un- derstanding between West Lake University and Chinese scholars in Australia. Through the infor- mation session,Chinese scholars in Australia can become acquainted with the projects and policies of West Lake University,with the hope of intensifying future academic collaborations and
                            project exchanges between them.</p>
                        <h3>- Shandong Innovation Group Sydney Doctoral
                            Roundtable</h3>
                        <p>The roundtable is designed to promote the talent and project implementation policies of Shandong Province.Through both one-on-one and group discussions, Chinese scholars in Australia can learn about the projects and research opportuni- ties in Shandong. The goal is to enhance ex- changes and collaborations between Shandong enterprises and various Australian universities.</p> 
                        <h3>- China-Australia Academic Exchange Lecture</h3>
                        <p>The lecture aims to present complex academic knowledge in a more accessible and understand- able manner. On one hand, it promotes the de velopment of science education,and on the other, it fosters mutual understanding and collab-
                            oration across various disciplines.</p>
                        <Link to="/program" className="cta-button">Next</Link>
                    </div>
                    <div className="about-image-section">
                        <img src="images/recent.jpg" alt="Promotional Seminars with Local Governments and Universities in China"/>
                    </div>
                </section>
            </main>

            <main className="mobile-main">
                <section className="about-section">
                    <div className="about-description-section">
                        <h1>Recent Projects 2023</h1>
                        <h3>- West Lake University Information Session</h3>
                        <div className="about-image-section">
                            <img src="images/xihu3.jpg" alt="Promotional Seminars with Local Governments and Universities in China"/>
                        </div>
                        <p>The event aims to foster communication and un- derstanding between West Lake University and Chinese scholars in Australia. Through the infor- mation session,Chinese scholars in Australia can become acquainted with the projects and policies of West Lake University,with the hope of intensifying future academic collaborations and
                            project exchanges between them.</p>
                        <h3>- Shandong Innovation Group Sydney Doctoral
                            Roundtable</h3>
                        <div className="about-image-section">
                            <img src="images/shandong.png" alt="Promotional Seminars with Local Governments and Universities in China"/>
                        </div>
                        <p>The roundtable is designed to promote the talent and project implementation policies of Shandong Province.Through both one-on-one and group discussions, Chinese scholars in Australia can learn about the projects and research opportuni- ties in Shandong. The goal is to enhance ex- changes and collaborations between Shandong enterprises and various Australian universities.</p> 
                        <h3>- China-Australia Academic Exchange Lecture</h3>
                        <div className="about-image-section">
                            <img src="images/business1.jpg" alt="Promotional Seminars with Local Governments and Universities in China"/>
                        </div>
                        <p>The lecture aims to present complex academic knowledge in a more accessible and understand- able manner. On one hand, it promotes the de velopment of science education,and on the other, it fosters mutual understanding and collab-
                            oration across various disciplines.</p>
                        <Link to="/program" className="cta-button">Next</Link>
                    </div>
                </section>
            </main>
        </div>
    );
}
  
  export default Recent;