import React, { useContext, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';
import axios from 'axios';
import baseURL from '../components/config';

const withAuthCheck = (WrappedComponent) => {
  return ({ ...props }) => {
    useEffect(() => {
      let lastActivityTime = Date.now();

      // Function to reset activity timeout
      const resetActivityTimeout = async () => {
        if (!localStorage.getItem('Email')) {
          console.error("Cannot reset activity time out: Email doesn't exist in localStorage")
          return;
        }
        let response = await axios.get(`${baseURL}/api/referesh_token?Email=${localStorage.getItem('Email')}`);
        localStorage.setItem('jwtToken', response.data.access_token);
      };

      // Function to handle user activity
      const handleUserActivity = () => {
        const currentActivityTime = Date.now();
        // resetActivityTimeout every minute
        if (currentActivityTime - lastActivityTime > 60000) {
          // console.log("reset");
          resetActivityTimeout();
          // Save user's current activity time
          lastActivityTime = currentActivityTime;
        }
      };

      // Add event listeners for user activity
      window.addEventListener('mousemove', handleUserActivity);
      window.addEventListener('keydown', handleUserActivity);
      window.addEventListener('scroll', handleUserActivity);

      // Reset activity timeout initially
      resetActivityTimeout();

      // Decode JWT token and check expiration
      const checkTokenExpiration = () => {
        try {
          const decodedToken = jwtDecode(localStorage.getItem('jwtToken'));
          const currentTime = Date.now() / 1000;
          // console.log(`decodedToken.exp is ${decodedToken.exp}, currentTime is ${currentTime}`);
          if (decodedToken.exp < currentTime) {
            alert("Your session has expired, Please log in again");
            window.location.href = '/#/login';
            localStorage.clear()
          }
        } catch (error) {
          console.error('Error decoding JWT token:', error);
        }
      };

      // Check token expiration every 5 seconds
      const expirationInterval = setInterval(checkTokenExpiration, 5000);

      // Cleanup
      return () => {
        clearInterval(expirationInterval);
        window.removeEventListener('mousemove', handleUserActivity);
        window.removeEventListener('keydown', handleUserActivity);
        window.removeEventListener('scroll', handleUserActivity);
      };
    }, [localStorage.getItem("jwtToken")]);

    useEffect(() => {
      const checkTokenExpiration = () => {
        try {
          const decodedToken = jwtDecode(localStorage.getItem('jwtToken'));
          const currentTime = Date.now() / 1000;
          // console.log(`decodedToken.exp is ${decodedToken.exp}, currentTime is ${currentTime}`);
          if (decodedToken.exp < currentTime) {
            alert("Your session has expired, Please log in again");
            window.location.href = '/#/login';
            localStorage.clear()
          }
        } catch (error) {
          console.error('Error decoding JWT token:', error);
        }
      };
      checkTokenExpiration();
    }, [])

    return (
      <>
        <WrappedComponent {...props} />
      </>
    );
  };
};

export default withAuthCheck;
