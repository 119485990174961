import React, { useState, useEffect } from 'react';
import { HashRouter as Router, Route, Routes, Navigate, Outlet } from "react-router-dom";
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import About from './pages/About';
import Business from './pages/Business';
import Contact from './pages/Contact';
import Educational from './pages/Educational';
import International from './pages/International';
import Mission from './pages/Mission';
import Events from './pages/Events';
import Offshore from './pages/Offshore';
import Onshore from './pages/Onshore';
import Program from './pages/Program';
import Recent from './pages/Recent';
import Login from './pages/Login';
import SignUp from './pages/SignUp';
import Profile from './pages/Profile';
import Users from './pages/Users';
import Forgot from './components/Forgot';
import Reset from './components/Reset';

import './App.css';
import CreateEvent from './pages/CreateEvent';
import Verify from './components/Verify';
import EventEdit from './pages/EventEdit';
import EventPage from './pages/EventPage';

function AdminRoute({ children }) {
  const isAdmin = localStorage.getItem("isAdmin");
  console.log(isAdmin)
  return isAdmin === "true" ? <>{children}</> : <Navigate to="/" />;
}

function AnonymousRoute({ children }) {
  const jwtToken = localStorage.getItem("jwtToken");
  console.log(jwtToken);
  // Check if token exist, if exist means user has logged in
  return jwtToken ? <Navigate to="/" /> : <>{children}</>;
}

function AuthedRoute({ children }) {
  const jwtToken = localStorage.getItem("jwtToken");
  return jwtToken ? <>{children}</> : <Navigate to="/login" />;
}

function AgentRoute({ children }) {
  const isAdmin = localStorage.getItem("isAdmin");
  const isAgent = localStorage.getItem("isAgent");
  return isAdmin == "true" || isAgent == "true" ? <>{children}</> : <Navigate to="/" />;
}

function clearLocalStorageOnUnload() {
  window.addEventListener('beforeunload', () => {
    localStorage.clear();
  });
}

function App() {
  // Call the function to clear localStorage on browser unload
  // clearLocalStorageOnUnload(); 
  return (
    <div>
      <Router>
        <Header />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/business_implementation" element={<Business />} />
          <Route exact path="/contact" element={<Contact />} />
          <Route exact path="/educational_project" element={<Educational />} />
          <Route exact path="/international_events" element={<International />} />
          <Route exact path="/mission" element={<Mission />} />
          <Route exact path="/offshore_support" element={<Offshore />} />
          <Route exact path="/onshore_support" element={<Onshore />} />
          <Route exact path="/program" element={<Program />} />
          <Route exact path="/recent_projects" element={<Recent />} />
          <Route exact path="/events" element={<Events />} />

          {/* Routes accessible only when user not logged in */}
          <Route
            exact path="/login"
            element={
              <AnonymousRoute>
                <Login />
              </AnonymousRoute>
            }
          />
          <Route
            exact path="/forgot"
            element={
              <AnonymousRoute>
                <Forgot />
              </AnonymousRoute>
            }
          />
          <Route
            path="/reset-password/:token"
            element={
              <AnonymousRoute>
                <Reset />
              </AnonymousRoute>
            }
          />
          <Route
            exact path="/verify"
            element={
              <AnonymousRoute>
                <Verify />
              </AnonymousRoute>
            }
          />
          <Route
            exact path="/signup"
            element={
              <AnonymousRoute>
                <SignUp />
              </AnonymousRoute>
            }
          />

          {/* Routes accessible only to logged in users */}
          <Route
            exact path="/profile"
            element={
              <AuthedRoute>
                <Profile />
              </AuthedRoute>
            }
          />
          <Route
            exact path="/create-event"
            element={
              <AuthedRoute>
                <CreateEvent />
              </AuthedRoute>
            }
          />
          <Route
            exact path="/events/:eventID"
            element={
              <EventPage />
            }
          />

          {/* Routes accessible only to admin users */}
          <Route
            exact path="/view_all_users"
            element={
              <AdminRoute>
                <Users />
              </AdminRoute>
            }
          />
          <Route
            exact path="/events/edit/:eventID"
            element={
              <AdminRoute>
                <EventEdit />
              </AdminRoute>
            }
          />
          <Route
            exact path="/profile/:ID"
            element={
              <AgentRoute>
                <Profile />
              </AgentRoute>
            }
          />

          {/* Route to catch unrecognized URLs and redirect to home */}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
