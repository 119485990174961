import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import baseURL from './config';

const Reset = () => {
    const navigate = useNavigate();
    const { token } = useParams(); // 获取 URL 中的令牌参数
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errorMsg, setErrorMsg] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (newPassword !== confirmPassword) {
            setErrorMsg('Passwords do not match');
            return;
        }
        try {
            // console.log(token)
            // console.log(confirmPassword)
            // 向后端发送请求以更新密码，需要发送token参数
            const response = await axios.post(`${baseURL}/api/reset-password/reset`, {
                password: confirmPassword, 
                token: token
            });
            // console.log(response.data);
            if (response.status == 200) {
                navigate('/login');
            }
        } catch (error) {
            console.error(error);
            setErrorMsg('Failed to reset password: The reset link is invalid or has expired. ');
        }
    };

    return (
        <div className="reset-password-container login-signup-container">
            <div className='form-header'>
                <div className='text'>Reset Password</div>
                <div className='underline'></div>
            </div>
            {errorMsg && <div className="error-msg">{errorMsg}</div>}
            <div className='inputs'>
                <div className='input'>
                    <input type="password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} placeholder="New Password" required />
                </div>
                <div className='input'>
                    <input type="password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} placeholder="Confirm Password" required />
                </div>
            </div>
            <div className='submit-container'><button className='cta-button' onClick={handleSubmit}>Reset Password</button></div>
        </div>
    );
}

export default Reset;
