import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import baseURL from './config';

const Verify = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [verifyCode, setVerifyCode] = useState('');
    const [errorMsg, setErrorMsg] = useState('');
    const [resendMsg, setResendMsg] = useState('');
    const [loading, setLoading] = useState(false);

    const handleVerifyCode = async () => {
        const { Email } = location.state || {};
        try {
            // Send request to backend to verify code
            const response = await axios.post(`${baseURL}/api/verify_code`, { Email: Email, code: verifyCode });
            // Call handleSubmit function to complete signup
            // console.log(response.data)
            if (response.status === 200) {
                await handleSubmit();
            }
        } catch (error) {
            console.error('Error verifying code:', error);
            setErrorMsg('Invalid verification code. Please try again.');
        } finally {
            setLoading(false); // Set loading to false when the request is complete (success or error)
        }
    };

    const handleSubmit = async () => {
        const { userName, Email, password, phoneNumber } = location.state || {};
        try {
            const response = await axios.post(`${baseURL}/api/signup`, { userName: userName, Email: Email, password: password, phoneNumber: phoneNumber });
            const accessToken = response.data.access_token;
            const isAdmin = response.data.isAdmin;
            const isAgent = response.data.isAgent;
            const userIDFromFresponse = response.data.userID;
            const userNameFromFresponse = response.data.userName;
            localStorage.setItem('jwtToken', accessToken);
            isAdmin === 0 ? localStorage.setItem('isAdmin', false) : localStorage.setItem('isAdmin', true);
            isAgent === 0 ?  localStorage.setItem('isAgent', false) : localStorage.setItem('isAgent', true);
            localStorage.setItem('userID', userIDFromFresponse);
            localStorage.setItem('userName', userNameFromFresponse);
            localStorage.setItem('isEditing', false);
            localStorage.setItem('Email', Email);
            navigate('/');
            window.location.reload();
        } catch (error) {
            console.error('Error signing up:', error);
            setErrorMsg('Error signing up. Please try again.');
        } 
    };

    const handleResendCode = async () => {
        setLoading(true); // Set loading to true when starting the request
        const { Email } = location.state || {};
        try {
            await axios.post(`${baseURL}/api/send_verification_email`, { Email: Email });
            alert('Verification code resent successfully.');
        } catch (error) {
            console.error('Error resending verification code:', error);
            setResendMsg('Error resending verification code. Please try again later.');
        } finally {
            setLoading(false); // Set loading to false when the request is complete (success or error)
        }
    };

    return ( 
        <div className="verify-container">
            <h1 className='verify-thing'>Verification</h1>
            <p className='verify-thing'>Please enter the verification code sent to your email.</p>
            <div className='buttonTotal'>
                <input className='verify-thing' type="text" onChange={(e) => setVerifyCode(e.target.value)} placeholder="Enter Verification Code" />
                <div className='resend'><button className={loading ? 'loading-button' : 'cta-button'} id='resend' onClick={handleResendCode} disabled={loading}>{loading ? 'Sending...' : 'Resend'}</button></div>
            </div>
            {errorMsg && <div className="error-msg">{errorMsg}</div>}
            <div className='verify-thing'>
                <button className='cta-button' onClick={handleVerifyCode}>Verify Code</button>
            </div>
            {resendMsg && <div className="resend-msg">{resendMsg}</div>}
        </div>
    );
}

export default Verify;
