import React from 'react';
import { Link  } from 'react-router-dom';

const About = () => {
    return ( 
        <div>
            <main className="pc-main">
            <section className="about-section">
                <div className="about-description-section">
                    <h1>About us</h1>
                    <p>The Australian Chinese Scholars Association (abbreviated as AACS) is a non-profit organization registered with the Australian government. Its registered office is located in Sydney, Australia. Our goal is to promote communication, exchange, and mutual assistance among the Chinese scholar community in Australia and to provide comprehensive support and services to our members.</p>
                    <p>Since our establishment, we have served over 1,000 doctoral and professorial scholars in Australia. The association adopts an individual membership system, and our members are mainly PhDs, doctoral advisors, and some investors and entrepreneurs in the technology industry from various academic fields in Australia.</p> 
                    <p>We maintain good cooperative relations with the Australian federal government, the New South Wales state government, and various provinces and cities in China.</p>
                    <Link  to="/mission" className="cta-button">Next</Link >
                </div>
                <div className="about-image-section">
                    <img src="images/business1.jpg" alt="about"/>
                </div>
            </section>
        </main>

        <main className="mobile-main">
            <section className="about-section">
                <h1>About us</h1>
                <div className="about-description-section">
                    <p>The Australian Chinese Scholars Association (abbreviated as AACS) is a non-profit organization registered with the Australian government. Its registered office is located in Sydney, Australia. Our goal is to promote communication, exchange, and mutual assistance among the Chinese scholar community in Australia and to provide comprehensive support and services to our members.</p>
                    <div className="about-image-section">
                        <img src="images/business1.jpg" alt="about"/>
                    </div>
                    <p>Since our establishment, we have served over 1,000 doctoral and professorial scholars in Australia. The association adopts an individual membership system, and our members are mainly PhDs, doctoral advisors, and some investors and entrepreneurs in the technology industry from various academic fields in Australia.</p> 
                    <p>We maintain good cooperative relations with the Australian federal government, the New South Wales state government, and various provinces and cities in China.</p>
                    <Link  to="/mission" className="cta-button">Next</Link >
                </div>
            </section>
        </main>
        </div>
    );
}
  
  export default About;