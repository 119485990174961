import React from 'react';
import { Link  } from 'react-router-dom';

const Onshore = () => {
    return ( 
        <div>
            <main className="pc-main">
                <section className="about-section">
                    <div className="about-description-section">
                        <h1>Onshore Entrepreneurial Support</h1>
                        <h2>-- Chunhui Cup Overseas Chinese Student Entrepreneurship Competition</h2>
                        <p>As the most important overseas talent entrepreneurship competition organized by China's Ministry of Education, we are responsible for collecting projects for the Sydney and Melbourne sub-divisions in Australia.</p>
                        <p>This provides Chinese scholars in Australia with a platform to showcase their entrepreneurial projects, gain resources, and receive investment support. Participants have theopportunity to connect with investors and businesses, obtaining entrepreneurial funding and collaboration opportunities.</p> 
                        <Link to="/offshore_support" className="cta-button">Next</Link>
                    </div>
                    <div className="about-image-section">
                        <img src="images/onshore-hz.jpg" alt="chunhui cup competition"/>
                    </div>
                </section>
            </main>

            <main className="mobile-main">
                <section className="about-section">
                    <div className="about-description-section">
                        <h1>Onshore Entrepreneurial Support</h1>
                        <h2>-- Chunhui Cup Overseas Chinese Student Entrepreneurship Competition</h2>
                        <div className="about-image-section">
                            <img src="images/Onshore.png" alt="chunhui cup competition"/>
                        </div>
                        <p>As the most important overseas talent entrepreneurship competition organized by China's Ministry of Education, we are responsible for collecting projects for the Sydney and Melbourne sub-divisions in Australia.</p>
                        <p>This provides Chinese scholars in Australia with a platform to showcase their entrepreneurial projects, gain resources, and receive investment support. Participants have theopportunity to connect with investors and businesses, obtaining entrepreneurial funding and collaboration opportunities.</p> 
                        <Link to="/offshore_support" className="cta-button">Next</Link>
                    </div>
                </section>
            </main>
        </div>
    );
}
  
  export default Onshore;