import React from 'react';
import { Link  } from 'react-router-dom';

const Offshore = () => {
    return ( 
        <div>
            <main className="pc-main">
                <section className="about-section">
                    <div className="about-description-section">
                        <h1>Offshore Entrepreneurial Support</h1>
                        <p>Innovation and Entrepreneurship Competition for Overseas Personnel in Various Provinces and Cities.</p>
                        <p>We have already established in-depth partnerships with innovation and entrepreneurship competitions, as well as project incubation and landing, in several cities including Beijing Zhongguancun, Nanjing and Wuxi in Jiangsu Province, Hangzhou and Jiaxing in Zhejiang Province, Shenzhen in Guangdong Province, and Chengdu in Sichuan Province.</p> 
                        <Link to="/educational_project" className="cta-button">Next</Link>
                    </div>
                    <div className="about-image-section">
                        <img src="images/offshore-hz.jpg" alt="Innovation and Entrepreneurship Competition"/>
                    </div>
                </section>
            </main>

            <main className="mobile-main">
                <section className="about-section">
                    <div className="about-description-section">
                        <h1>Offshore Entrepreneurial Support</h1>
                        <p>Innovation and Entrepreneurship Competition for Overseas Personnel in Various Provinces and Cities.</p>
                        <div className="about-image-section">
                            <img src="images/Offshore.png" alt="Innovation and Entrepreneurship Competition"/>
                        </div>
                        <p>We have already established in-depth partnerships with innovation and entrepreneurship competitions, as well as project incubation and landing, in several cities including Beijing Zhongguancun, Nanjing and Wuxi in Jiangsu Province, Hangzhou and Jiaxing in Zhejiang Province, Shenzhen in Guangdong Province, and Chengdu in Sichuan Province.</p> 
                        <Link to="/educational_project" className="cta-button">Next</Link>
                    </div>
                </section>
            </main>
        </div>
    );
}
  
  export default Offshore;