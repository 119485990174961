import React, {useEffect, useState} from 'react';
import RefreshableLink from './RefereshableLink';
import axios from 'axios';
import baseURL from './config';
import { useNavigate } from 'react-router-dom';

function Header() {
    const navigate = useNavigate();

    // Get JWT token from local storage
    const [jwtToken, setJwtToken] = useState(null);

    const [isAdmin, setIsAdmin] = useState(false);

    useEffect(() => {
        // Get JWT token from local storage
        const token = localStorage.getItem('jwtToken');
        setJwtToken(token)
        // console.log(token)

        // Get isAdmin from local storage
        const localIsAdmin = localStorage.getItem('isAdmin');
        
        // Since localStorage only store data in string || null, we need to convert localIsAdmin to boolean
        localIsAdmin === null || localIsAdmin === 'false' ? setIsAdmin(false) : setIsAdmin(true);
        
        // console.log(localIsAdmin)
        // console.log(isAdmin)
    }, []);

    function toggleDropdown() {
        var dropdownMenu = document.getElementById("dropdownMenu");
        dropdownMenu.classList.toggle("show");

        var closeDropdownMenu = document.getElementById("dropdownMenu2");
        closeDropdownMenu.classList.remove("show");
    }

    function toggleDropdown2() {
        var dropdownMenu = document.getElementById("dropdownMenu2");
        dropdownMenu.classList.toggle("show");

        var closeDropdownMenu = document.getElementById("dropdownMenu");
        closeDropdownMenu.classList.remove("show");
    }

    function toggleSubDropdown(id) {
        var subDropdown = document.getElementById(id);
        subDropdown.classList.toggle("show-sub");
    }

    const handleLogout = async () => {
        try {
            const response = await axios.post(`${baseURL}/api/logout`, {}, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('jwtToken')}`
                }
            });
            // console.log(response.data);
            alert(response.data.message);
        } catch (error) {
            console.error('Error logging out:', error);
            alert('Error logging out:', error);
        }

        // Clear local storage
        localStorage.clear()

        // Perform page refresh to home page
        navigate('/');
        window.location.reload();
    };

    // Close the dropdown menu if the user clicks outside of it
    window.onclick = function(event) {
        if (!event.target.matches('.dropbtn') && !event.target.matches('.dropbtn img') && !event.target.matches('.sub-dropbtn')) {
            var dropdowns = document.getElementsByClassName("dropdown-content");
            for (var i = 0; i < dropdowns.length; i++) {
                var openDropdown = dropdowns[i];
                if (openDropdown.classList.contains('show')) {
                    openDropdown.classList.remove('show');
                }
            }

            var dropdowns2 = document.getElementsByClassName("dropdown-content2");
            for (var z = 0; z < dropdowns.length; z++) {
                var openDropdown2 = dropdowns2[z];
                if (openDropdown2.classList.contains('show')) {
                    openDropdown2.classList.remove('show');
                }
            }
        }
        if (!event.target.matches('.sub-dropbtn')) {
            var subDropdowns = document.getElementsByClassName("sub-dropdown-content");
            for (var j = 0; j < subDropdowns.length; j++) {
                var openSubDropdown = subDropdowns[j];
                if (openSubDropdown.classList.contains('show-sub')) {
                    openSubDropdown.classList.remove('show-sub');
                }
            }
        }
        if (!event.target.matches('.foot-sub-dropbtn')) {
            var FooterDropdowns = document.getElementsByClassName("footer-sub-dropdown-content");
            for (var x = 0; x < FooterDropdowns.length; x++) {
                var openFooterDropdown = FooterDropdowns[x];
                if (openFooterDropdown.classList.contains('footer-show-sub')) {
                    openFooterDropdown.classList.remove('footer-show-sub');
                }
            }
        }
    }

    return(
        <div>
            <header className="pc-header">
                {/* <!-- Logo linking to index --> */}
                <div id="logo"><RefreshableLink to="/"><img src="images/logo-up.png" alt="AACS Logo" /></RefreshableLink></div>

                {/* <!-- Navigation links --> */}
                <div id="sub-menu">
                    <div><RefreshableLink to="/about">About</RefreshableLink></div>
                    <div><RefreshableLink to="/mission">Our Mission</RefreshableLink></div>
                    <div><RefreshableLink to="/events">Events</RefreshableLink></div>
                    <div className="has-dropdown">
                        <RefreshableLink to="/onshore_support">Support</RefreshableLink>
                        <ul className="drop-menu">
                            <li><RefreshableLink to="/onshore_support">Onshore</RefreshableLink></li>
                            <li><RefreshableLink to="/offshore_support">Offshore</RefreshableLink></li>
                        </ul>
                    </div>
                    <div className="has-dropdown">
                        <RefreshableLink to="/educational_project">Project</RefreshableLink>
                        <ul className="drop-menu">
                            <li><RefreshableLink to="/educational_project">Educational</RefreshableLink></li>
                            <li><RefreshableLink to="/recent_projects">Recent Projects</RefreshableLink></li>
                        </ul>
                    </div>
                    <div><RefreshableLink to="/contact">Contact us</RefreshableLink></div>

                    {
                        jwtToken ?
                        <div className="has-dropdown">
                            <img id='profile-icon' src="images/profile_icon.png" alt="User profile logo" />
                            <ul className="drop-menu">
                                <li><RefreshableLink to="/profile">User Profile</RefreshableLink></li>
                                {  
                                    isAdmin ?
                                    <li><RefreshableLink to="/view_all_users">View All Users</RefreshableLink></li>
                                    :
                                    <li></li>
                                }
                                <li><div onClick={handleLogout}><RefreshableLink>Logout</RefreshableLink></div></li>
                                
                            </ul>
                        </div>
                        :
                        <RefreshableLink to="/Login">Login</RefreshableLink>
                    }
                </div>
            </header>

            <header className="mobile-header">
                {/* <!-- Logo linking to index --> */}
                <div className="dropdown">
                    <div id="logo"><RefreshableLink to="/"><img src="images/logo-center.png" alt="AACS Logo" /></RefreshableLink></div>

                    <button onClick={toggleDropdown} className="dropbtn">
                        <img src="images/filter-icon.png" alt="Menu"/>
                    </button>

                    {
                        jwtToken ?
                        <div>
                            <button onClick={toggleDropdown2} className="dropbtn">
                                <img id='profile-icon' src="images/profile_icon.png" alt="User profile logo" />
                            </button>
                        </div>
                        :
                        <div className='dropbtn2'><RefreshableLink to="/Login">Login</RefreshableLink></div>
                    }
                </div>

                <div id="dropdownMenu" className="dropdown-content">
                    <RefreshableLink to="/about">About</RefreshableLink>
                    <RefreshableLink to="/mission">Our Mission</RefreshableLink>
                    <RefreshableLink to="/events">Events</RefreshableLink>
                    <div className="sub-dropdown">
                        <button onClick={() => toggleSubDropdown('subdropMenu1')} className="sub-dropbtn">Support+</button>
                        <div id="subdropMenu1" className="sub-dropdown-content">
                            <RefreshableLink to="/onshore_support">Onshore Support</RefreshableLink>
                            <RefreshableLink to="/offshore_support">Offshore Support</RefreshableLink>
                        </div>
                    </div>
                    <div className="sub-dropdown">
                        <button onClick={() => toggleSubDropdown('subdropMenu2')} className="sub-dropbtn">Project+</button>
                        <div id="subdropMenu2" className="sub-dropdown-content">
                            <RefreshableLink to="/educational_project">Educational</RefreshableLink>
                            <RefreshableLink to="/recent_projects">Recent Projects</RefreshableLink>
                        </div>
                    </div>
                    <RefreshableLink to="/contact">Contact Us</RefreshableLink>
                </div>

                <div id="dropdownMenu2" className="dropdown-content2">
                    <RefreshableLink to="/profile">User Profile</RefreshableLink>
                    {  
                        isAdmin ?
                        <RefreshableLink to="/view_all_users">View All Users</RefreshableLink>
                        :
                        <div></div>
                    }
                    <RefreshableLink onClick={handleLogout}>Logout</RefreshableLink>
                    
                </div>
            </header>
        </div>
    );
}

Header.propTypes = {
    // userEmail: PropTypes.any,
}

export default Header;