import React from 'react';
import { Link  } from 'react-router-dom';

const Business = () => {
    return ( 
        <div>
            <main className="pc-main">
                <section className="about-section">
                    <div className="about-description-section">
                        <h1>Business Implementation And R&D Business</h1>
                        <h3>Assisting in Technology lmplementation and Cross-company R&D</h3>
                        <p>Yantai Zhizi Security Co., Ltd. has collaborated with a PhD from the Universityof Sydney on a video anti-fraud joint research and development project.</p>
                        <h3>Chongqing Chengtoubiao Technology Co., Ltd. has partnered with a professorfrom Peking University on an urban twin cooperation project.</h3>
                        <p>Xiamen Yan's House Bioengineering Co.. Ltd. has worked with Professor Wenfrom the Institute of Automation, Chinese Academy of Sciences, on anautomated bird's nest weighing and Al visual feather-picking project.</p>
                        <Link to="/contact" className="cta-button" id="contact-button">Contact Us</Link>
                    </div>
                    <div className="about-image-section">
                        <img src="images/business-all.jpg" alt="Business Implementation And R&D Business"/>
                    </div>
                </section>
            </main>

            <main className="mobile-main">
                <section className="about-section">
                    <h1>Business Implementation And R&D Business</h1>
                    <div className="about-description-section">
                        <h3>Assisting in Technology lmplementation and Cross-company R&D</h3>
                        <div className="about-image-section">
                            <img src="images/business1.jpg" alt="about"/>
                        </div>
                        <p>Yantai Zhizi Security Co., Ltd. has collaborated with a PhD from the Universityof Sydney on a video anti-fraud joint research and development project.</p>
                        <br/>
                        <h3>Chongqing Chengtoubiao Technology Co., Ltd. has partnered with a professorfrom Peking University on an urban twin cooperation project.</h3>
                        <div className="about-image-section">
                            <img src="images/Mission2.jpg" alt="about"/>
                        </div>
                        <p>Xiamen Yan's House Bioengineering Co.. Ltd. has worked with Professor Wenfrom the Institute of Automation, Chinese Academy of Sciences, on anautomated bird's nest weighing and Al visual feather-picking project.</p>
                        <Link to="/contact" className="cta-button" id="contact-button">Contact Us</Link>
                    </div>
                </section>
            </main>
        </div>
    );
}
  
  export default Business;