import React, { useState, useEffect } from 'react';

const UniversitySelect = ({ isEditing, formData, setFormData, type }) => {
    const isUndergraduate = type === 'undergraduate';
    const selectedValue = isUndergraduate
        ? formData["graduateSchool"] || ''
        : formData["phdGraduateSchool"] || '';
    const predefinedUniversities = [
        "Australian Catholic University",
        "Australian National University",
        "Bond University",
        "Charles Darwin University",
        "Charles Sturt University",
        "Curtin University",
        "Deakin University",
        "Edith Cowan University",
        "Federation University",
        "Flinders University",
        "Griffith University",
        "James Cook University",
        "La Trobe University",
        "Macquarie University",
        "Monash University",
        "Murdoch University",
        "Queensland University of Technology",
        "RMIT University",
        "Southern Cross University",
        "Swinburne University of Technology",
        "University of Adelaide",
        "University of Canberra",
        "University of Divinity",
        "University of Melbourne",
        "University of New England",
        "University of New South Wales",
        "University of Newcastle",
        "University of Queensland",
        "University of South Australia",
        "University of Southern Queensland",
        "University of Sydney",
        "University of Tasmania",
        "University of Technology Sydney",
        "University of the Sunshine Coast",
        "University of Western Australia",
        "University of Wollongong",
        "Victoria University",
        "Western Sydney University",
        "其他"
    ];

    // State to manage other university input
    const [otherUniversity, setOtherUniversity] = useState(
        !predefinedUniversities.includes(selectedValue) && selectedValue !== '其他'
            ? selectedValue
            : ""
    );

    const handleUniversityChange = (e) => {
        const selectedUniversity = e.target.value;

        if (selectedUniversity === "其他") {
            // If "其他" is selected, clear the current university and enable "otherUniversity" input
            isUndergraduate
                ? setFormData({ ...formData, graduateSchool: "其他" })
                : setFormData({ ...formData, phdGraduateSchool: "其他" });
        } else {
            // Update the selected value in formData
            isUndergraduate
                ? setFormData({ ...formData, graduateSchool: selectedUniversity })
                : setFormData({ ...formData, phdGraduateSchool: selectedUniversity });

            // Clear "otherUniversity" if switching back to a predefined option
            setOtherUniversity("");
        }
    };

    const handleOtherUniversityChange = (e) => {
        setOtherUniversity(e.target.value);
    };

    const saveOtherUniversity = () => {
        // Save the custom university to formData
        if (otherUniversity.trim() !== "") {
            isUndergraduate
                ? setFormData({ ...formData, graduateSchool: otherUniversity })
                : setFormData({ ...formData, phdGraduateSchool: otherUniversity });
        }
    };

    const isOtherSelected = selectedValue === "其他";

    return (
        <div className="userInfo-container">
            <div className="userInfo">
                {isUndergraduate
                    ? "Undergraduate Graduate School/本科毕业学校"
                    : "PhD Graduate School/博士毕业学校"}
            </div>
            <select
                className="userAnswer"
                disabled={!isEditing}
                value={
                    isOtherSelected && otherUniversity
                        ? otherUniversity
                        : selectedValue
                }
                onChange={handleUniversityChange}
            >
                {/* Predefined universities */}
                <option value="">--Select University--</option>
                {predefinedUniversities.map((university, index) => (
                    <option key={index} value={university}>
                        {university}
                    </option>
                ))}

                {/* Dynamically add "otherUniversity" as an option */}
                {!predefinedUniversities.includes(selectedValue) &&
                    selectedValue && (
                        <option value={selectedValue}>{selectedValue}</option>
                    )}
            </select>

            {/* Input for "其他" or a non-listed university */}
            {isOtherSelected && (
                <div className="userInfo-container-other">
                    <div className="userInfo">
                        Please Specify/其他学校说明
                    </div>
                    <input
                        className="userAnswer"
                        type="text"
                        value={otherUniversity}
                        onChange={handleOtherUniversityChange}
                        disabled={!isEditing}
                        placeholder="Specify the university"
                    />
                    {isEditing && (
                        <button
                            type="button"
                            className="add-button"
                            onClick={saveOtherUniversity}
                            disabled={!isEditing || !otherUniversity}
                        >
                            Add
                        </button>
                    )}
                </div>
            )}
        </div>
    );
};

export default UniversitySelect;
