import React, { useState } from 'react';

const MultiSelectEmployer = ({ isEditing, formData, setFormData }) => {
    const [expectEmployer, setExpectEmployer] = useState('');
    const [otherEmployer, setOtherEmployer] = useState('');
    const [chosenEmployers, setChosenEmployers] = useState(formData.chosenEmployers);
    const [showAddButton, setShowAddButton] = useState(false);

    const maxLengths = {
        otherEmployer: 50
    };
    
    const handleEmployer = (e) => {
        const selectedValue = e.target.value;
        setExpectEmployer(selectedValue);
        if (selectedValue === '其他') {
            setShowAddButton(true);
        } else {
            setShowAddButton(false);
            if (selectedValue) {
                const isEmployerAlreadyChosen = chosenEmployers.some(employer => employer === selectedValue);
                if (!isEmployerAlreadyChosen) {
                    setChosenEmployers([...chosenEmployers, selectedValue]);
                    setFormData({ ...formData, 'chosenEmployers': [...chosenEmployers, selectedValue]});
                } else {
                    alert("This employer has already been added!");
                }
            }
            setExpectEmployer("");
            setOtherEmployer("");
        }
        
    };

    const handleOtherEmployer = (e) => {
        setOtherEmployer(e.target.value);
    };

    const handleAddOtherEmployer = () => {
        if (otherEmployer) {
            const isEmployerAlreadyChosen = chosenEmployers.some(employer => employer === otherEmployer);
            if (!isEmployerAlreadyChosen) {
                setChosenEmployers([...chosenEmployers, otherEmployer]);
                setFormData({ ...formData, 'chosenEmployers': [...chosenEmployers, otherEmployer]});
                setOtherEmployer('');
            } else {
                alert("This employer has already been added!");
            } 
        }
    };

    const handleRemoveEmployer = (index) => {
        if (isEditing) {
            const updatedEmployers = [...chosenEmployers];
            updatedEmployers.splice(index, 1);
            setChosenEmployers(updatedEmployers);
            setFormData({ ...formData, 'chosenEmployers': updatedEmployers});
        }
    };

    return (
        <div className='userInfo-container'>
            <div className='userInfo'>Expected Employer/期待工作单位</div>
            <select className='userAnswer' disabled={!isEditing} value={expectEmployer} onChange={handleEmployer}>
                <option value="">--Select Option--</option>
                <option value="无要求">无要求</option>
                <option value="高校">高校</option>
                <option value="国企">国企</option>
                <option value="政府机构">政府机构</option>
                <option value="创业">创业</option>
                <option value="其他">其他</option>
            </select>
            {/* Display chosen employers as tags */}
            <div className="tag-container">
                {chosenEmployers.map((employer, index) => (
                    <span key={index} className="tag">
                        {employer}
                        <button onClick={() => handleRemoveEmployer(index)}>x</button>
                    </span>
                ))}
            </div>
            {expectEmployer === "其他" && (
                <div className='userInfo-container-other'>
                    <div className='userInfo'>Please Specify/其他工作单位说明</div>
                    <input className='userAnswer' type="text" maxLength={maxLengths.otherEmployer} onChange={handleOtherEmployer} value={otherEmployer} disabled={!isEditing}/>
                    {showAddButton && <button className='add-button' onClick={handleAddOtherEmployer} disabled={!isEditing || !otherEmployer}>Add</button>}
                </div> 
            )}
        </div>

    );
}

export default MultiSelectEmployer;
