import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import baseURL from '../components/config';

const SignUp = () => {
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        userName: '',
        Email: '',
        phoneNumber: '',
        password: ''
    });
    const [errorMsg, setErrorMsg] = useState('');
    const [NameErrorMsg, setNameErrorMsg] = useState('');
    const [isPasswordValid, setIsPasswordValid] = useState(true);
    const [isEmailAvailable, setIsEmailAvailable] = useState(true);
    const [isUserNameAvailable, setIsUserNameAvailable] = useState(true);
    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        // Reset error messages when user types in email or password
        setErrorMsg('');
        setIsPasswordValid(true);
        setIsEmailAvailable(true);
        setIsUserNameAvailable(true);
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    function handlePhoneChange(event) {
        const input = event.target.value;
        const numericInput = input.replace(/\D/g, '');
        setFormData({ ...formData, 'phoneNumber' : numericInput });
    }

    const validateEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    const validatePassword = (password) => {
        return password.length >= 6;
    };

    const handleSendVerifyCode = async () => {
        setLoading(true); // Set loading to true when starting the request

        const Email  = formData.Email;
        const userName = formData.userName;
        const password = formData.password;
        const phoneNumber = formData.phoneNumber;
        
        if (!validateEmail(Email)) {
            setErrorMsg('Invalid email format');
            setLoading(false); // Set loading to false if validation fails
            return;
        }

        if (!validatePassword(password)) {
            setIsPasswordValid(false);
            setErrorMsg('Password must be at least 6 characters long');
            setLoading(false); // Set loading to false if validation fails
            return;
        }

        try {
            // Check if email is available
            const response = await axios.post(`${baseURL}/api/check_email_availability`, { Email: Email });
            if (!response.data.available) {
                setIsEmailAvailable(false);
                setErrorMsg('Email is already registered');
                setLoading(false); // Set loading to false if email is not available
                return;
            }

            // Check if userName is available
            const response2 = await axios.post(`${baseURL}/api/check_user_name_availability`, { userName: userName });
            if (!response2.data.available) {
                setIsUserNameAvailable(false);
                setNameErrorMsg('User name is already taken');
                setLoading(false); // Set loading to false if username is not available
                return;
            }

            // Send request to backend to generate and send verify code
            await axios.post(`${baseURL}/api/send_verification_email`, { Email: Email });
            // Redirect to Verify page after sending verify code
            navigate('/verify', { state: { Email: Email, userName: userName, phoneNumber: phoneNumber, password: password  } });
        } catch (error) {
            console.error('Error sending verify code:', error);
            alert('Error sending verify code:', error);
        } finally {
            setLoading(false); // Set loading to false when the request is complete (success or error)
        }
    };

    return (
        <div className="login-signup-container">
            <div className='form-header'>
                <div className='text'>Sign Up</div>
                <div className='underline'></div>
            </div>
            <div className='inputs'>
                <div className='input'>
                    <div className='input-img-container'>
                        <img src='images/profile_icon.png' alt='profile'/>
                    </div>
                    <input type="text" onChange={handleChange} name='userName' id="userName" placeholder="Your Name" required/>
                    {!isUserNameAvailable && <div className="error-msg">{NameErrorMsg}</div>}
                </div>
                <div className='input'>
                    <div className='input-img-container'>
                        <img src='images/email_icon.png' alt='email' style={{ width: '85%', height: '85%', padding: '2px'}}/>
                    </div>    
                    <input type="email" onChange={handleChange} name="Email" id="Email" placeholder="Your Email" required/>
                    {!isEmailAvailable && <div className="error-msg">{errorMsg}</div>}
                </div>
                <div className='input'>
                    <div className='input-img-container'>
                        <img src='images/phone_icon.png' alt='phone' style={{ width: '80%', height: '80%', padding: '2px'}}/>
                    </div>
                    <input type="tel" onChange={handlePhoneChange} name="phoneNumber" id="phone" placeholder="Your Phone Number"/>
                </div>
                <div className='input'>
                    <div className='input-img-container'>    
                        <img src='images/password_icon.png' alt='password'/>
                    </div>
                    <input type="password" onChange={handleChange} name="password" id="password" placeholder="Enter Your Password"/>
                    {!isPasswordValid && <div className="error-msg">{errorMsg}</div>}
                </div>
            </div>
            <div className='switch-signup-login'>
                Already have an account?<Link to="/Login"> Click here to login</Link>
            </div>
            <div className='submit-container'>
                <button className={loading ? 'loading-button' : 'cta-button'} onClick={handleSendVerifyCode} disabled={loading}>{loading ? 'Sending...' : 'Verify Email to Sign Up'}</button>
            </div>
        </div>
    );    
}

export default SignUp;
