import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Event from '../components/Event';
import axios from 'axios';
import baseURL from '../components/config';
import { useNavigate } from 'react-router-dom';

function Home() {
    const [approvedEvents, setApprovedEvents] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchTopEvents = async () => {
            try {
                const response = await axios.get(`${baseURL}/api/events/top`);
                if (response.status === 200) {
                    // Get the top three events
                    const topEvents = response.data.top_events;
                    setApprovedEvents(topEvents);
                    // console.log(approvedEvents);
                }
            } catch (error) {
                console.error('Error fetching top events:', error);
            }
        };

        fetchTopEvents();
    }, []);


    return (
        <div>
            <main className="home-main">
                <section className="hero">
                    {/* <!-- Hero section introducing the organization --> */}
                    <div className="about-info">
                        <h1>Welcome to the Australian Chinese Scholars Association</h1>
                        <p>The Austtalian Chinese Scholars Association (abbreviated as AACS)is a non-profit organization registered with the Australian government, with its registered office located in Sydney, Australia.</p>
                        <p>Our aim is to foster communication, collaboration, and mutual aid within the Chinese scholar community in Australia. We are committed to delivering extensive support and services to our members.</p>
                        {/* <!-- Add a call-to-action button linking to 'About' or another important section --> */}
                        <br />
                        <Link to="/about" className="cta-button" onClick={() => window.location.reload()}>Learn More</Link>
                    </div>
                </section>
                <br />

                <section className="highlights">
                    {/* <!-- Highlight key sections of the website --> */}
                    <div className="mission-info">
                        <h2>Our Mission</h2>
                        <p>Our goal is to promote communication,exchange, and mutual assistance among the Chinese scholar community in Australia and to provide comprehensive support and services to our members.</p>
                        <p>Since our establishment, we have served over 1,000 doctoral and professorial scholars in Australia.</p>
                        <p>The association adopts an individual membership system, and our members are mainly PhDs, doctoral advisors.and some investors and entrepreneurs in the technology industry from various academic fields in Australia.</p>
                        <p>We maintain good cooperative relations with the Australian federal government, the New South Wales state government, and various provinces and cities in China.</p>
                        <div><Link to="/mission">Explore</Link></div>
                    </div>
                    <div className="side-image-container">
                        <img src="images/Mission2.jpg" alt="Mission" />
                    </div>
                    {/* <!-- Include other highlight boxes for different sections --> */}
                </section>

                {/* Top Events */}
                {
                    approvedEvents ?
                        <section className='topevents'>
                            <div className="events-info">
                                <h2>Top Events</h2>
                                <div className='event-main-section'>
                                    {approvedEvents.map(event => (
                                        <div className="top-event" key={event.eventID}>
                                            <h3>{event.eventName}</h3>
                                            <p style={{ maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{event.description}</p>
                                            <Link to={`/events/${event.eventID}`}>details</Link>
                                        </div>
                                    ))}
                                </div>
                                {/* <div><Link to="/events">Go to Events</Link></div> */}
                            </div>
                        </section>
                        :
                        null
                }

                <section className="multi-container-section">
                    <div className="image-container">
                        <Link to="/onshore_support" onClick={() => window.location.reload()}>
                            <img src="images/Onshore.png" alt="onshore" />
                        </Link>
                        <Link to="/onshore_support">
                            <div className="title">Onshore Entrepreneurial Support</div>
                        </Link>
                    </div>

                    <div className="image-container">
                        <Link to="/offshore_support" onClick={() => window.location.reload()}>
                            <img src="images/Offshore.png" alt="offshore" />
                        </Link>
                        <Link to="/offshore_support" onClick={() => window.location.reload()}>
                            <div className="title">Offshore Entrepreneurial Support</div>
                        </Link>
                    </div>

                    <div className="image-container">
                        <Link to="/educational_project" onClick={() => window.location.reload()}>
                            <img src="images/Educational.jpg" alt="Education" />
                        </Link>
                        <Link to="/educational_project" onClick={() => window.location.reload()}>
                            <div className="title">Education Cooperation Project</div>
                        </Link>
                    </div>
                </section>

                <section className="multi-container-section">
                    <div className="image-container">
                        <Link to="/program" onClick={() => window.location.reload()}>
                            <img src="images/torch-program.jpg" alt="torch-program" />
                        </Link>
                        <Link to="/program" onClick={() => window.location.reload()}>
                            <div className="title">Sino-Australian Torch Program</div>
                        </Link>
                    </div>

                    <div className="image-container">
                        <Link to="/international_events" onClick={() => window.location.reload()}>
                            <img src="images/international.jpg" alt="international events" />
                        </Link>
                        <Link to="/international_events" onClick={() => window.location.reload()}>
                            <div className="title">International Events</div>
                        </Link>
                    </div>

                    <div className="image-container">
                        <Link to="/business_implementation" onClick={() => window.location.reload()}>
                            <img src="images/business1.jpg" alt="business implementation" />
                        </Link>
                        <Link to="/business_implementation" onClick={() => window.location.reload()}>
                            <div className="title">Business Implementation And R&D Business</div>
                        </Link>
                    </div>
                </section>

                <br />
            </main>

            <main className="mobile-main">
                <section className="hero">
                    {/* <!-- Hero section introducing the organization --> */}
                    <div className="about-info">
                        <h1>Welcome to the Australian Chinese Scholars Association</h1>
                        <br />
                        <p>The Austtalian Chinese Scholars Association (abbreviated as AACS)is a non-profit organization registered with the Australian government, with its registered office located in Sydney, Australia.</p>
                        <br />
                        <p>Our aim is to foster communication, collaboration, and mutual aid within the Chinese scholar community in Australia. We are committed to delivering extensive support and services to our members.</p>
                        {/* <!-- Add a call-to-action button linking to 'About' or another important section --> */}
                        <br />
                        <img src="images/Mission2.jpg" alt="meeting" />
                        <div className="mobile-hor-center"><Link to="/about" className="hero-button" onClick={() => window.location.reload()}>Learn More</Link></div>
                    </div>
                </section>

                <br />

                <section className="highlights">
                    {/* <!-- Highlight key sections of the website --> */}
                    <div className="mission-info">
                        <h2>Our Mission</h2>
                        <p>Our goal is to promote communication, exchange, and mutual assistance among the Chinese scholar community in Australia and to provide comprehensive support and services to our members.</p>
                        <p>We maintain good cooperative relations with the Australian federal government, the New South Wales state government, and various provinces and cities in China.</p>
                        <div><Link to="/mission" onClick={() => window.location.reload()}>Explore</Link></div>
                    </div>
                </section>

                {/* Top Events 部分 */}
                <h1 id="advocacy-title">Top Events</h1>
                <section className='topevents'>
                    <div className="event-main-section">
                        {approvedEvents.map(event => (
                            <div className="top-event" key={event.eventID}>
                                <h3>{event.eventName}</h3>
                                <p style={{ maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{event.description}</p>
                                <Link to={`/events/${event.eventID}`}>details</Link>
                            </div>
                        ))}
                        {/* <div className="top-event" ><Link to="/events">Go to Events</Link></div> */}
                    </div>
                </section>

                <h1 id="advocacy-title">Advocacy</h1>
                <div className="multi-content-section">
                    <div className="image-container">
                        <Link to="/onshore_support" onClick={() => window.location.reload()}>
                            <img src="images/Onshore.png" alt="onshore" />
                        </Link>
                        <Link to="/onshore_support" onClick={() => window.location.reload()}>
                            <div className="title">Onshore Entrepreneurial Support</div>
                        </Link>
                    </div>

                    <div className="image-container">
                        <Link to="/offshore_support" onClick={() => window.location.reload()}>
                            <img src="images/Offshore.png" alt="offshore" />
                        </Link>
                        <Link to="/offshore_support" onClick={() => window.location.reload()}>
                            <div className="title">Offshore Entrepreneurial Support</div>
                        </Link>
                    </div>

                    <div className="image-container">
                        <Link to="/educational_project" onClick={() => window.location.reload()}>
                            <img src="images/Educational.jpg" alt="Education" />
                        </Link>
                        <Link to="/educational_project" onClick={() => window.location.reload()}>
                            <div className="title">Education Cooperation Project</div>
                        </Link>
                    </div>

                    <div className="image-container">
                        <Link to="/program" onClick={() => window.location.reload()}>
                            <img src="images/torch-program.jpg" alt="torch-program" />
                        </Link>
                        <Link to="/program" onClick={() => window.location.reload()}>
                            <div className="title">Sino-Australian Torch Program</div>
                        </Link>
                    </div>

                    <div className="image-container">
                        <Link to="/international_events" onClick={() => window.location.reload()}>
                            <img src="images/international.jpg" alt="international events" />
                        </Link>
                        <Link to="/international_events" onClick={() => window.location.reload()}>
                            <div className="title">International Events</div>
                        </Link>
                    </div>

                    <div className="image-container">
                        <Link to="/business_implementation" onClick={() => window.location.reload()}>
                            <img src="images/business1.jpg" alt="business implementation" />
                        </Link>
                        <Link to="/business_implementation" onClick={() => window.location.reload()}>
                            <div className="title">Business Implementation And R&D Business</div>
                        </Link>
                    </div>
                </div>
            </main>
        </div>
    );
}

export default Home;