import React, { useState } from 'react';

const Filter = ({ options, onSelect }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState(
        options.reduce((acc, curr) => {
            acc[curr.name] = [];
            return acc;
        }, {})
    );

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleOptionClick = (option, value) => {
        const updatedOptions = { ...selectedOptions };

        // Toggle the option selection
        if (updatedOptions[option] && updatedOptions[option].includes(value)) {
            updatedOptions[option] = updatedOptions[option].filter((item) => item !== value);
        } else {
            updatedOptions[option] = [...(updatedOptions[option] || []), value];
        }

        setSelectedOptions(updatedOptions);
    };
    
    const handleApplyFilter = () => {
        // Pass selected options to onSelect function
        onSelect(selectedOptions);
        // Close dropdown after applying filter
        setIsOpen(false);
    };

    const handleReset = () => {
        // Reset selected options
        setSelectedOptions({});
        // Pass an empty string to indicate no filters
        onSelect('');
        // Close dropdown after resetting
        setIsOpen(false);
    };

    return (
        <div className="filter">
            <div className="filter-icon" onClick={toggleDropdown}>
                <img id='filter-icon' src="images/filter.png" alt="Filter Icon"/>
            </div>
            {isOpen && (
                <div className="filter-dropdown">
                    {options.map((option, index) => (
                        <div key={index} className="option">
                            <div className="header">
                                {option.name}
                            </div>
                            {option.children && (
                                <div className="children">
                                    {option.children.map((child, childIndex) => (
                                        <label key={childIndex} className="child">
                                            <input
                                                type="checkbox"
                                                value={child}
                                                checked={selectedOptions[option.name] && selectedOptions[option.name].includes(child)}
                                                onChange={() => handleOptionClick(option.name, child)}
                                            />
                                            {child}
                                        </label>
                                    ))}
                                </div>
                            )}
                        </div>
                    ))}
                    <div className='buttonTotal'>
                        <button className='cta-button' onClick={handleApplyFilter}>Apply</button>
                        <button className='cta-button' onClick={handleReset}>Reset</button>
                    </div>  
                </div>
            )}
        </div>
    );
};

export default Filter;
