import React from 'react';
import { Link  } from 'react-router-dom';

const International = () => {
    return ( 
        <div>
            <main className="pc-main">
                <section className="about-section">
                    <div className="about-description-section">
                        <h1>International Events</h1>
                        <h3>Entrepreneurial Thought Sharing Session</h3>
                        <p>This event offers a platform for successful entrepreneurs to share theirexperiences and reflections with our members. Participants can gaininsights and quidance from these shared experiences, providinginvaluable advice and lessons for their entrepreneurial journey.</p>
                        <h3>G5 Entrepreneurial Event</h3>
                        <p>The G5 Entrepreneurial Event aims to foster communication andcollaboration between Chinese scholars in Australia and entrepreneursThrough seminars and networking sessions, scholars can learn fromone another, drawing inspiration and broadening their globalentrepreneurial perspectives.</p>
                        <Link to="/business_implementation" className="cta-button">Next</Link>
                    </div>
                    <div className="about-image-section">
                        <img src="images/international-all.jpg" alt="International Events"/>
                    </div>
                </section>
            </main>

            <main className="mobile-main">
                <section className="about-section">
                    <div className="about-description-section">
                        <h1>International Events</h1>
                        <h3>Entrepreneurial Thought Sharing Session</h3>
                        <div className="about-image-section">
                            <img src="images/international.jpg" alt="International Events"/>
                        </div>
                        <p>This event offers a platform for successful entrepreneurs to share theirexperiences and reflections with our members. Participants can gaininsights and quidance from these shared experiences, providinginvaluable advice and lessons for their entrepreneurial journey.</p>
                        <h3>G5 Entrepreneurial Event</h3>
                        <div className="about-image-section">
                            <img src="images/international2.jpg" alt="International Events"/>
                        </div>
                        <p>The G5 Entrepreneurial Event aims to foster communication andcollaboration between Chinese scholars in Australia and entrepreneursThrough seminars and networking sessions, scholars can learn fromone another, drawing inspiration and broadening their globalentrepreneurial perspectives.</p>
                        <Link to="/business_implementation" className="cta-button">Next</Link>
                    </div>
                </section>
            </main>
        </div>
    );
}
  
  export default International;