import React from 'react';
import { Link  } from 'react-router-dom';

const Educational = () => {
    return ( 
        <div>
            <main className="pc-main">
                <section className="about-section">
                    <div className="about-description-section">
                        <h1>Education Cooperation Project</h1>
                        <p>We have already cooperated with the governments of Nanjing, Xuzhou, and Huai'an in Jiangsu Province; Hangzhou and Jiaxing in Zhejiang Province; Guangzhou and Shenzhen in GuangdongProvince; as well as Hebei Province and Shaanxi Province.</p>
                        <p>We have also partnered with numerous well-known universitiesto hold several talent and intelligence attraction seminars. As a result, we have become talent and intelligence attraction workstations for several provincial and municipal governments.</p> 
                        <Link to="/recent_projects" className="cta-button">Next</Link>
                    </div>
                    <div className="about-image-section">
                        <img src="images/Educational.jpg" alt="Promotional Seminars with Local Governments and Universities in China"/>
                    </div>
                </section>
            </main>

            <main className="mobile-main">
                <section className="about-section">
                    <div className="about-description-section">
                        <h1>Education Cooperation Project</h1>
                        <p>We have already cooperated with the governments of Nanjing, Xuzhou, and Huai'an in Jiangsu Province; Hangzhou and Jiaxing in Zhejiang Province; Guangzhou and Shenzhen in GuangdongProvince; as well as Hebei Province and Shaanxi Province.</p>
                        <div className="about-image-section">
                            <img src="images/Educational.jpg" alt="Promotional Seminars with Local Governments and Universities in China"/>
                        </div>
                        <p>We have also partnered with numerous well-known universitiesto hold several talent and intelligence attraction seminars. As a result, we have become talent and intelligence attraction workstations for several provincial and municipal governments.</p> 
                        <Link to="/recent_projects" className="cta-button">Next</Link>
                    </div>
                </section>
            </main>
        </div>
    );
}
  
  export default Educational;