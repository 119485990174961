import React from 'react';
import RefreshableLink from './RefereshableLink';

class Footer extends React.Component {
    render() {
        function toggleFooterDropdown(id) {
            var subDropdown = document.getElementById(id);
            subDropdown.classList.toggle("footer-show-sub");
        }

        // Close the dropdown menu if the user clicks outside of it
        window.onclick = function(event) {
            if (!event.target.matches('.dropbtn') && !event.target.matches('.dropbtn img') && !event.target.matches('.sub-dropbtn')) {
                var dropdowns = document.getElementsByClassName("dropdown-content");
                for (var i = 0; i < dropdowns.length; i++) {
                    var openDropdown = dropdowns[i];
                    if (openDropdown.classList.contains('show')) {
                        openDropdown.classList.remove('show');
                    }
                }
            }
            if (!event.target.matches('.sub-dropbtn')) {
                var subDropdowns = document.getElementsByClassName("sub-dropdown-content");
                for (var j = 0; j < subDropdowns.length; j++) {
                    var openSubDropdown = subDropdowns[j];
                    if (openSubDropdown.classList.contains('show-sub')) {
                        openSubDropdown.classList.remove('show-sub');
                    }
                }
            }
            if (!event.target.matches('.foot-sub-dropbtn')) {
                var FooterDropdowns = document.getElementsByClassName("footer-sub-dropdown-content");
                for (var x = 0; x < FooterDropdowns.length; x++) {
                    var openFooterDropdown = FooterDropdowns[x];
                    if (openFooterDropdown.classList.contains('footer-show-sub')) {
                        openFooterDropdown.classList.remove('footer-show-sub');
                    }
                }
            }
        }

        return(
            <div>
                <footer className="site-footer">
                    <div className="footer-links">
                        <div className="column">
                            <h4>About</h4>
                            <ul>
                                <li><RefreshableLink to="/about">About Us</RefreshableLink></li>
                                <li><RefreshableLink to="/mission">Our Mission</RefreshableLink></li>
                                <li><RefreshableLink to="/contact">Contact Us</RefreshableLink></li>
                                <li><RefreshableLink to="/onshore_support">Onshore Entrepreneurial Support</RefreshableLink></li>
                                <li><RefreshableLink to="/offshore_support">Offshore Entrepreneurial Support</RefreshableLink></li>
                            </ul>
                        </div>
                        <div className="column">
                            <h4>Advocacy</h4>
                            <ul>
                                <li><RefreshableLink to="/educational_project">Education Cooperation Project</RefreshableLink></li>
                                <li><RefreshableLink to="/program">Sino-Australian Torch Program</RefreshableLink></li>
                                <li><RefreshableLink to="/international_events">International Events</RefreshableLink></li>
                                <li><RefreshableLink to="/business_implementation">Business Implementation And R&D Business</RefreshableLink></li>
                                <li><RefreshableLink to="/recent_projects">Recent Projects</RefreshableLink></li>
                            </ul>
                        </div>
                        {/* <!-- Add more columns with different categories if required --> */}
                    </div>
                    <div className="copyright">
                        <p>&copy; 2023 AACS - All Rights Reserved.</p>
                    </div>
                </footer>

                <footer className="mobile-footer">
                    <div className="footer-dropdown">
                        <button onClick={() => toggleFooterDropdown('subdropMenu3')} className="foot-sub-dropbtn">About+</button>
                        <div id="subdropMenu3" className="footer-sub-dropdown-content">
                            <RefreshableLink to="/about">About Us</RefreshableLink>
                            <RefreshableLink to="/mission">Our Mission</RefreshableLink>
                            <RefreshableLink to="/contact">Contact Us</RefreshableLink>
                            <RefreshableLink to="/onshore_support">Onshore Entrepreneurial Support</RefreshableLink>
                            <RefreshableLink to="/offshore_support">Offshore Entrepreneurial Support</RefreshableLink>
                        </div>
                    </div>
                    <div className="footer-dropdown">
                        <button onClick={() => toggleFooterDropdown('subdropMenu4')} className="foot-sub-dropbtn">Advocacy+</button>
                        <div id="subdropMenu4" className="footer-sub-dropdown-content">
                            <RefreshableLink to="/educational_project">Education Cooperation Project</RefreshableLink>
                            <RefreshableLink to="/program">Sino-Australian Torch Program</RefreshableLink>
                            <RefreshableLink to="/international_events">International Events</RefreshableLink>
                            <RefreshableLink to="/business_implementation">Business Implementation And R&D Business</RefreshableLink>
                            <RefreshableLink to="/recent_projects">Recent Projects</RefreshableLink>
                        </div>
                    </div>
                    
                    <div className="copyright">
                        <p>&copy; 2023 AACS - All Rights Reserved.</p>
                    </div>
                </footer>
            </div>
        );
    }
}

export default Footer;