import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import baseURL from './config';

const Forgot = () => {
    const [userEmail, setUserEmail] = useState({ Email: '' });
    const [errorMsg, setErrorMsg] = useState('');
    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        setUserEmail({ 'Email': e.target.value });
    };

    const handleResetPassword = async (e) => {
        setLoading(true); // Set loading to true when starting the request
        e.preventDefault();
        try {
            // 向后端发送请求以更新密码，需要发送token参数
            const response = await axios.post(`${baseURL}/api/reset-password/send_email`, userEmail);
            alert(response.data)
        } catch (error) {
            console.error(error);
            setErrorMsg('Failed to reset password');
        } finally {
            setLoading(false); // Set loading to false when the request is complete (success or error)
        }
    };

    return (
        <div className="forgot-password-container login-signup-container">
            <div className='form-header'>
                <div className='text'>Forgot Password</div>
                <div className='underline'></div>
            </div>
            {errorMsg && <div className="error-msg">{errorMsg}</div>}
            <div className='inputs'>
                <div className='input'>
                    <input type="email" onChange={handleChange} name='Email' id="Email" placeholder="Your Email" required />
                </div>
            </div>
            <div className='forgot-password'>
                Remember your password?<Link to="/login"> Login</Link>
            </div>
            <div className='submit-container'><button className={loading ? 'loading-button' : 'cta-button'} onClick={handleResetPassword}disabled={loading}>{loading ? 'Sending...' : 'Send Reset Code'}</button></div>
        </div>
    );
}

export default Forgot;
