import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import WithAuthCheck from '../components/withAuthCheck';
import baseURL from '../components/config';

const CreateEvent = () => {
    const [jwtToken, setJwtToken] = useState(null);
    
    const navigate = useNavigate();
    const [errorMsg, setErrorMsg] = useState('');
    const [isFilled, setIsFilled] = useState(true);

    const [formData, setFormData] = useState({
        eventName: '',
        description: '',
        deadline: '',
        isAdmin: 0,
        owner: '',
        ownerID: '',
        status: 0
    });

    const maxLengths = {
        eventName: 60,
        description: 5000
    }

    useEffect(() => {
        // Get JWT token from local storage
        const token = localStorage.getItem('jwtToken');
        setJwtToken(token);

        // Get isAdmin, userID and userName from local storage
        const localUserName = localStorage.getItem('userName');
        const localUserID = localStorage.getItem('userID');
        const localIsAdmin = localStorage.getItem('isAdmin');
        if (localIsAdmin === "true") {
            setFormData({ 
                ...formData, 
                'owner': localUserName, 
                'ownerID': localUserID,
                'isAdmin': 1,
                'status': 1
            });
        } else {
            setFormData({ 
                ...formData, 
                'owner': localUserName, 
                'ownerID': localUserID,
                'isAdmin': 0,
                'status': 0
            });
        }
    }, []);

    

    const handleChange = (e) => {
        setIsFilled(true);
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleCreateEvent = async () => {
        if (formData.eventName.trim() === '' || formData.description.trim() === '' || formData.deadline.trim() === '') {
            alert('Please fill all information.');
            setErrorMsg('Please fill all information.')
            setIsFilled(false);
            return;
        }
        
        try {
            const response = await axios.post(`${baseURL}/api/events/create`, formData, {
                headers: {
                    Authorization: `Bearer ${jwtToken}`
                }
            });
            // console.log(response.data)

            navigate('/events');
            window.location.reload();
            if (localStorage.getItem('isAdmin') !== 'true') {
                alert("Waiting approve")
            }
        } catch (error) {
            console.error('Failed to create event:', error);
            alert('Failed to create event. Please try again.');
        }
    };

    const handleCancel = () => {
        navigate('/events');
    };

    return (
        <div className="profile-container">
            <h1 className='profile-title'>Create Event</h1>
            <form>
                <div className="userInfo-container">
                    <label htmlFor="title">Title</label>
                    <input className='userAnswer' maxLength={maxLengths.eventName} type="text" name="eventName" id="title" value={formData.eventName} onChange={handleChange} required />
                </div>
                <div className="userInfo-container">
                    <label htmlFor="description">Description</label>
                    <textarea className='userAnswer' maxLength={maxLengths.description} name="description" id="description" value={formData.Description} onChange={handleChange} required />
                </div>
                <div className="userInfo-container">
                <label htmlFor="deadline">Deadline</label>
                <input
                    className='userAnswer'
                    type="date"
                    name="deadline"
                    id="deadline"
                    value={formData.deadline}
                    onChange={handleChange}
                    required
                    min={new Date().toISOString().split('T')[0]}
                />
            </div>
                {!isFilled && <div className="error-msg">{errorMsg}</div>}
                <div className='profile-edit-div'>
                    <div className="buttonFlex">
                        <button className='cta-button' id='confirm' type="button" onClick={handleCreateEvent}>Confirm</button>
                        <button className='cta-button' type="button" onClick={handleCancel}>Cancel</button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default WithAuthCheck(CreateEvent);
