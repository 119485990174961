import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import baseURL from '../components/config';

const Login = () => {
    const navigate  = useNavigate();

    const [formData, setFormData] = useState({
        Email: '',
        password: ''
    });
    const [errorMsg, setErrorMsg] = useState('');
    
    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${baseURL}/api/login`, formData);
            const accessToken = response.data.access_token;
            const isAdmin = response.data.isAdmin;
            const isAgent = response.data.isAgent;
            const userID = response.data.userID;
            const userName = response.data.userName;
            
            // debug
            // console.log(response.data)
            // console.log(accessToken);
            // console.log(formData);

            if (response.status === 200) {
                // Store the JWT token in local storage
                localStorage.setItem('jwtToken', accessToken);
                // console.log(localStorage.getItem('jwtToken'))

                // Store relavent data in local storage
                isAdmin === 0 ? localStorage.setItem('isAdmin', false) : localStorage.setItem('isAdmin', true);
                isAgent === 0 ?  localStorage.setItem('isAgent', false) : localStorage.setItem('isAgent', true);
                localStorage.setItem('userID', userID);
                localStorage.setItem('userName', userName);
                localStorage.setItem('isEditing', false);
                localStorage.setItem('Email', formData.Email);

                // Navigate to another page
                navigate('/');

                // Perform page refresh
                window.location.reload();
            }
        } catch (error) {
            setErrorMsg('Invalid email or password');
            console.error(errorMsg);
        }
    };

    return ( 
        <div className="login-signup-container">
            <div className='form-header'>
                <div className='text'>Login</div>
                <div className='underline'></div>
            </div>
            {errorMsg && <div className="error-msg">{errorMsg}</div>}
            <div className='inputs'>
                <div className='input'>
                    <div className='input-img-container'>
                        <img src='images/email_icon.png' alt='email' style={{ width: '85%', height: '85%', padding: '2px'}}/>
                    </div>    
                    <input type="email" onChange={handleChange} name='Email' id="Email" placeholder="Your Email" required/>
                </div>
                <div className='input'>
                    <div className='input-img-container'>
                        <img src='images/password_icon.png' alt='password'/>
                    </div>    
                    <input type="password" onChange={handleChange} name='password' id="password" placeholder="Enter Your Password"/>
                </div>
            </div>
            <div className='forgot-password'>
                Forgot Password?<Link to="/forgot">Click Here</Link>
            </div>
            <div className='switch-signup-login'>
                Don't have an account?<Link to="/signup"> Create an account</Link>
            </div>
            <div className='submit-container'><button className='cta-button' onClick={handleSubmit}>Login</button></div>
        </div>
    );    
}

Login.propTypes = {
    // setUserEmail: PropTypes.func,
}
  
export default Login;
