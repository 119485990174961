import React, { useEffect, useState } from 'react';

const MultiSelectCity = ({ pca, isEditing, formData, setFormData}) => {
    const [stateID, setStateID] = useState('');
    const [cityID, setCityID] = useState('');
    const [chosenCities, setChosenCities] = useState(formData.chosenCities);

    const handleAddCity = (e) => {
        if (stateID !== "" && cityID !== "") {
            // Check if the city already exists in chosenCities
            const isCityAlreadyChosen = chosenCities.some(city => city === pca[parseInt(stateID)][parseInt(cityID)]);
            
            if (!isCityAlreadyChosen) {
                // Add the city to chosenCities
                chosenCities.push(pca[parseInt(stateID)][parseInt(cityID)]);

                // Clear the city selection after adding
                setCityID("");
                setStateID("");
            } else {
                alert("This city has already been chosen.");
            }
        }
    }

    const handleCity = (e) => {
        // console.log(chosenCities);
        setCityID(e.target.value);
        if (stateID && e.target.value) {
            setChosenCities([...chosenCities, pca[parseInt(stateID)][parseInt(e.target.value)]]);
            setFormData({ ...formData, 'chosenCities': [...chosenCities, pca[parseInt(stateID)][parseInt(e.target.value)]]});
            setStateID('');
            setCityID('');
        }
        handleAddCity(e)
    };

    const handleRemoveCity = (index) => {
        if (isEditing) {
            const newChosenCities = [...chosenCities];
            newChosenCities.splice(index, 1);
            setFormData({ ...formData, 'chosenCities': newChosenCities});
            setChosenCities(newChosenCities);
            // console.log(newChosenCities, formData.chosenCities);
        }
    };

    return (
        <div className='userInfo-container'>
            <div className='userInfo'>City/城市</div>
            <select className='userAnswer' disabled={!isEditing} value={stateID} onChange={(e) => setStateID(e.target.value)}>
                <option value="">--Select Option--</option>
                {Object.keys(pca[86]).map((key) => (
                    <option value={key} key={key}>{pca[86][parseInt(key)]}</option>
                ))}
            </select>
            <select className='userAnswer' disabled={!isEditing || stateID === ""} value={cityID} onChange={handleCity}>
                <option value="">--Select Option--</option>
                {stateID !== "" && Object.keys(pca[parseInt(stateID)]).map((key) => (
                    <option value={key} key={key}>{pca[parseInt(stateID)][parseInt(key)]}</option>
                ))}
            </select>
            {/* Display chosen cities as tags */}
            <div className="tag-container">
                {chosenCities.map((city, index) => (
                    <span key={index} className="tag">
                        {city}
                        <button onClick={() => handleRemoveCity(index)} readOnly={!isEditing}>x</button>
                    </span>
                ))}
            </div>
        </div>
    );
}

export default MultiSelectCity;